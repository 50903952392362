import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUser,
  setAuthChecked,
  setAccounts,
  setTObjects,
  setTransactions,
  setSettings,
} from "../user";
import { api } from "../../utils/user-api";
import { AppDispatch } from "../store";
import Cookies from "universal-cookie";

export const getUser = () => {
  return (dispatch: AppDispatch) => {
    return api.getUser().then((res) => {
      if (res && res.user !== null) {
        dispatch(setUser({ user: res.user, loaded: true }));
      } else {
        dispatch(setUser({ user: null, loaded: false }));
      }
    });
  };
};

export const prelogin = createAsyncThunk(
  "user/prelogin",
  async (payload: { username: string }) => {
    const res = await api.prelogin(payload);
    return res;
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (payload: { username: string; password: string }) => {
    const res = await api.login(payload);

    const cookies = new Cookies(null, { path: "/" });
    cookies.set("accessToken", res.accessToken);

    if (res.user) {
      return { ...res, loaded: true };
    }

    return res;
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (payload: {
    username: string;
    password: string;
    email: string;
    phone: string;
    name: string;
  }) => {
    const res = await api.register(payload);

    const cookies = new Cookies(null, { path: "/" });
    cookies.set("accessToken", res.accessToken);

    if (res.user) {
      return { ...res, loaded: true };
    }

    return res;
  }
);

// export const userUpdate = (params) => {
//   return (dispatch:AppDispatch) => {
//     return api.update(params).then((res) => {
//       dispatch(setUser(res.user));
//     });
//   };
// };

export const checkUserAuth = () => {
  return (dispatch: AppDispatch) => {
    const cookies = new Cookies(null, { path: "/" });

    if (cookies.get("accessToken")) {
      dispatch(getUser())
        .catch(() => {
          // localStorage.removeItem("accessToken");
          dispatch(setUser({ user: null, loaded: false }));
        })
        .finally(() => dispatch(setAuthChecked(true)));
    } else {
      dispatch(setAuthChecked(true));
    }
  };
};

export const getAccounts = () => {
  return (dispatch: AppDispatch) => {
    return api.getAccounts().then((res) => {
      if (res && res.accounts !== null) {
        dispatch(setAccounts(res.accounts));
      } else {
        dispatch(setAccounts(null));
      }
    });
  };
};

export const addAccount = createAsyncThunk(
  "account/add",
  async (payload: {
    id?: string;
    name: string;
    currency_code: string;
    icon: number;
    balance: number;
    notes: string;
  }) => {
    const res = await api.addAccount(payload);
    return res.accounts;
  }
);
export const deleteAccount = createAsyncThunk(
  "account/delete",
  async (payload: { id: string }) => {
    const res = await api.deleteAccount(payload);
    return res;
  }
);

export const getTObjects = () => {
  return (dispatch: AppDispatch) => {
    return api.getTObjects().then((res) => {
      if (res && res.objects !== null) {
        dispatch(setTObjects(res.objects));
      } else {
        dispatch(setTObjects(null));
      }
    });
  };
};

export const addTObject = createAsyncThunk(
  "objects/add",
  async (payload: {
    id?: string;
    name: string;
    icon: number;
    notes: string;
  }) => {
    const res = await api.addTObject(payload);
    return res.objects;
  }
);

export const deleteTObject = createAsyncThunk(
  "objects/delete",
  async (payload: { id: string }) => {
    const res = await api.deleteTObject(payload);
    return res;
  }
);

export const closeUserSession = createAsyncThunk(
  "userSession/delete",
  async (payload: { session_id: string }) => {
    const res = await api.closeUserSession(payload);
    return res;
  }
);

export const getTransactions = () => {
  return (dispatch: AppDispatch) => {
    return api.getTransactions().then((res) => {
      if (res && res.transactions !== null) {
        dispatch(setTransactions(res.transactions));
      } else {
        dispatch(setTransactions(null));
      }
    });
  };
};

export const addTransaction = createAsyncThunk(
  "transaction/add",
  async (payload: {
    sum: number;
    account_id: string;
    currency_code: string;
    type: "expense" | "upcoming";
  }) => {
    const res = await api.addTransaction(payload);
    return res;
  }
);

export const getSettings = () => {
  return (dispatch: AppDispatch) => {
    return api.getSettings().then((res) => {
      if (res && res.settings !== null) {
        dispatch(
          setSettings({
            settings: { ...res.settings, loaded: true },
            sessions: res.sessions,
          })
        );
      }
    });
  };
};
export const setTheme = createAsyncThunk(
  "settings/theme",
  async (payload: 0 | 1 | 2, thunkAPI) => {
    const res = await api.setTheme(payload);
    thunkAPI.dispatch(
      setSettings({ settings: { ...res.settings, loaded: true } })
    );
    return res;
  }
);

export const logout = createAsyncThunk("user/logout", async () => {
  await api.logout();

  const cookies = new Cookies(null, { path: "/" });
  cookies.remove("accessToken");
});
