import { useEffect, useRef, useState } from "react";
import styles from "./currencies-form.module.css";
import { useSelector } from "../../services/store";
import { ICurrency } from "../../utils/types";

interface ICurrenciesForm {
  onSelect: (currencyItem: ICurrency) => void;
  activeItem: string;
}

const CurrenciesForm = ({ onSelect, activeItem }: ICurrenciesForm) => {
  const appConfig = useSelector((state) => state.app);

  const searchRef = useRef<HTMLInputElement | null>(null);

  const [searchValue, setSearchValue] = useState("");
  const [searchedItems, setSearchedItems] = useState(appConfig.currencies);

  useEffect(() => {
    if (appConfig.currencies) {
      if (searchValue.length > 0) {
        setSearchedItems(
          appConfig.currencies.filter((item) =>
            (
              item.name.toLowerCase() +
              " " +
              item.country.toLowerCase() +
              " " +
              item.code.toLowerCase() +
              " " +
              item.symbol.toLowerCase()
            ).includes(searchValue.toLowerCase())
          )
        );
      } else {
        setSearchedItems(appConfig.currencies);
      }
    }
  }, [searchValue, appConfig.currencies]);

  const onSearchInput = () => {
    if (searchRef.current) {
      setSearchValue(searchRef.current.value);
    }
  };

  return (
    <div className={`${appConfig.darkMode && styles.darkMode} ${styles.block}`}>
      <div className={styles.searchBlock}>
        <input
          ref={searchRef}
          onInput={onSearchInput}
          type="text"
          className={styles.searchInput}
          placeholder="Введите страну или валюту"
          value={searchValue}
        />
      </div>
      <div className={styles.currencies}>
        {searchedItems && searchedItems.length > 0 ? (
          searchedItems.map((item, index) => (
            <div
              key={index}
              className={`${styles.currenciesItem} ${
                activeItem === item.code ? styles.active : ""
              }`}
              onClick={() => onSelect(item)}
            >
              <div className={styles.symbol}>{item.symbol}</div>
              <div className={styles.info}>
                <div className={styles.heading}>
                  <span className={styles.code}>{item.code}</span>
                  <span className={styles.name}>{item.name}</span>
                </div>
                <div className={styles.country}>{item.country}</div>
              </div>
            </div>
          ))
        ) : (
          <>Ничего не найдено</>
        )}
      </div>
    </div>
  );
};

export default CurrenciesForm;
