import React from "react";
import ReactDOM from "react-dom/client";
import "./css/common.css";
import "./css/box.css";
import "./css/index.css";
import App from "./components/app/app";
import reportWebVitals from "./reportWebVitals";
import { MemoryRouter, BrowserRouter as Router } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./services/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MemoryRouter>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </MemoryRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
