import { Link, useLocation } from "react-router-dom";
import styles from "./accounts-item.module.css";
import { useSelector } from "../../services/store";
import { IAccount } from "../../utils/types";

type TCurrency = string;
type FormatDateProps = {
  item?: IAccount;
};

const AccountsItem = ({ item }: FormatDateProps) => {
  const appConfig = useSelector((state) => state.app);

  const formattedSum = (sum: number): string => {
    return new Intl.NumberFormat("ru-RU").format(sum);
  };

  const formattedCurrency = (currency_code: TCurrency) => {
    if (appConfig.currencies) {
      return appConfig.currencies.filter(
        (item) => item.code === currency_code
      )[0]?.symbol;
    } else {
      return "";
    }
  };

  const location = useLocation();

  const Icon = ({ item }: { item: IAccount }) => {
    if (appConfig.icons && item.icon && Number(item.icon) !== 0) {
      return appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
        ?.type === "img" ? (
        <img
          className={styles.icon}
          src={
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.url
          }
          alt={item.name}
        />
      ) : (
        <div className={styles.symbolIcon}>
          {
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.symbol
          }
        </div>
      );
    } else {
      return (
        <div className={styles.currencyIcon}>
          {formattedCurrency(item.currency_code)}
        </div>
      );
    }
  };

  return (
    <div
      className={`${styles.itemWrap} ${appConfig.darkMode && styles.darkMode}`}
    >
      {item ? (
        <Link to={`/accounts/${item.id}`} className={styles.item}>
          <div className={styles.name}>{item.name}</div>
          <div className="d-flex align-items-center">
            <Icon item={item} />

            <div className={styles.futureSum}>
              <span className={styles.sum}>{formattedSum(item.balance)}</span>
              <span className={styles.currency}>
                {formattedCurrency(item.currency_code)}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          to="/accounts/add"
          state={{ background: location }}
          className={styles.addItem}
        >
          <div className={styles.name}>Добавить счет</div>
          <div className={styles.plus}>+</div>
        </Link>
      )}
    </div>
  );
};

export default AccountsItem;
