import AffarioBlocks from "../affario-blocks/affario-blocks";
import AffarioBlocksItem from "../affario-blocks-item/affario-blocks-item";
import Login from "../../pages/login";
import Signup from "../../pages/signup";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  login as loginAction,
  prelogin as preloginAction,
} from "../../services/actions/user";
import { useDispatch } from "../../services/store";

const Auth = () => {
  const [activeCard, setActiveCard] = useState(1);

  useEffect(() => {
    setActiveCard(1);
  }, []);

  const [usernameValue, setUsernameValue] = React.useState("");

  const [passwordValue, setPasswordValue] = React.useState("");
  const [preloginName, setPreloginName] = React.useState("");
  const [errorValue, setErrorValue] = React.useState("");

  const onCheckEmail = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorValue("");
    if (usernameValue.length > 4) {
      dispatch(
        preloginAction({
          username: usernameValue,
        })
      )
        .then((res) => res)
        .then((res) => {
          if (res.payload.error) {
            setErrorValue("Пользователь не найден");
          } else if (res.payload.user.id) {
            setPreloginName(res.payload.user.name);
            setActiveCardFunc(2);
          }
        });
    }
  };

  const setActiveCardFunc = (id: number) => {
    setErrorValue("");
    setActiveCard(id);
  };

  const dispatch = useDispatch();

  const onLogin = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorValue("");
    dispatch(
      loginAction({
        username: usernameValue,
        password: passwordValue,
      })
    )
      .then((res) => res)
      .then((res) => {
        if (res.payload.error) {
          setErrorValue("Неправильный пароль");
        }
      });
  };

  return (
    <div className="d-flex align-items-center h-100 justify-content-center">
      <div className="main-form-default">
        <AffarioBlocks currentId={activeCard}>
          <AffarioBlocksItem itemId={1}>
            <Login
              section="email"
              usernameValue={usernameValue}
              setUsernameValue={setUsernameValue}
              onCheckEmail={onCheckEmail}
              setActiveCard={setActiveCardFunc}
              error={errorValue}
            />
          </AffarioBlocksItem>
          <AffarioBlocksItem itemId={2}>
            <Login
              section="password"
              passwordValue={passwordValue}
              setPasswordValue={setPasswordValue}
              onLogin={onLogin}
              setActiveCard={setActiveCardFunc}
              usernameValue={usernameValue}
              preloginName={preloginName}
              error={errorValue}
            />
          </AffarioBlocksItem>
          <AffarioBlocksItem itemId={3}>
            <Signup setActiveCard={setActiveCardFunc} />
          </AffarioBlocksItem>
          <AffarioBlocksItem itemId={0}>
            <div className="logotype-text sm">
              Affario<span>POPPYSEEDKING</span>
            </div>
            <span className="msg">Авторизация...</span>
          </AffarioBlocksItem>
        </AffarioBlocks>
      </div>
    </div>
  );
};

export default Auth;
