import AppHeader from "../components/app-header/app-header";
import MainControlPanel from "../components/main-control-panel/main-control-panel";
import ExpenseObjects from "../components/expense-objects/expense-objects";
import UpcomingDeposits from "../components/upcoming-deposits/upcoming-deposits";
import UpcomingExpenses from "../components/upcoming-expenses/upcoming-expenses";
import TransactionHistory from "../components/transaction-history/transaction-history";
import Accounts from "../components/accounts/accounts";
import {
  getCurrencies,
  getIcons,
  getIconsCategories,
} from "../services/actions/app-data";
import { useEffect } from "react";
import { useDispatch } from "../services/store";
import {
  checkUserAuth,
  getAccounts,
  getSettings,
  getTransactions,
  getUser,
} from "../services/actions/user";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());

    // dispatch(getSettings()).then(() => {
    //   dispatch(getCurrencies()).then(() => {
    //     dispatch(getIcons()).then(() => {
    //       dispatch(getIconsCategories()).then(() => {
    //         dispatch(getAccounts()).then(() => {
    //           dispatch(getTransactions());
    //         });
    //       });
    //     });
    //   });
    // });
  }, [dispatch]);

  return (
    <>
      <AppHeader />
      <main className="content">
        <Accounts />
        <ExpenseObjects />
        <div>
          <UpcomingDeposits />
          <UpcomingExpenses />
          <TransactionHistory max={8} />
        </div>
      </main>
      <MainControlPanel />
    </>
  );
};

export default Home;
