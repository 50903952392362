import { useSelector } from "../../services/store";
import styles from "./ui.module.css";

interface IButton {
  children: React.ReactNode | React.ReactNode[];
  buttonStyle?: "danger" | "light";
  type?: "submit" | "button";
  onClick?: () => void;
  className?: string;
}

const Button = ({
  children,
  buttonStyle,
  type = "submit",
  onClick,
  className,
}: IButton) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${styles.button} ${darkMode ? styles.darkMode : ""} ${
        buttonStyle ? styles[buttonStyle] : ""
      } ${className ? className : ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
