import {
  setCategoriesSets,
  setCurrencies,
  setIcons,
  setIconsCategories,
} from "../app";
import { api } from "../../utils/app-data-api";
import { AppDispatch } from "../store";

export const getCurrencies = () => {
  return (dispatch: AppDispatch) => {
    return api.getCurrencies().then((res) => {
      if (res && res.currencies !== null) {
        dispatch(setCurrencies(res.currencies));
      } else {
        dispatch(setCurrencies(null));
      }
    });
  };
};

export const getIcons = () => {
  return (dispatch: AppDispatch) => {
    return api.getIcons().then((res) => {
      if (res && res.icons !== null) {
        dispatch(setIcons(res.icons));
      } else {
        dispatch(setIcons(null));
      }
    });
  };
};

export const getIconsCategories = () => {
  return (dispatch: AppDispatch) => {
    return api.getIconsCategories().then((res) => {
      if (res && res.iconsCategories !== null) {
        dispatch(setIconsCategories(res.iconsCategories));
      } else {
        dispatch(setIconsCategories(null));
      }
    });
  };
};

export const getCategoriesSets = () => {
  return (dispatch: AppDispatch) => {
    return api.getCategoriesSets().then((res) => {
      if (res && res.categoriesSet !== null) {
        dispatch(setCategoriesSets(res.categoriesSet));
      } else {
        dispatch(setCategoriesSets(null));
      }
    });
  };
};
