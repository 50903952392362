import Modal from "../modal/modal";
import Page from "../page/page";

interface IAcceptWrap {
  onSubmit: () => void;
  sendForm: () => void;
  useAcceptButton?: boolean;
  title?: string;
  children: React.ReactNode | React.ReactNode[];
  useModal?: boolean;
}

const AcceptWrap = ({
  children,
  useModal,
  onSubmit,
  sendForm,
  title,
  useAcceptButton
}: IAcceptWrap) => {
  return (
    <>
      {useModal ? (
        <Modal
          title={title}
          onClose={onSubmit}
          useAcceptButton={useAcceptButton}
          onAccept={sendForm}
        >
          {children}
        </Modal>
      ) : (
        <Page
          title={title}
          useAcceptButton={useAcceptButton}
          onAccept={sendForm}
        >
          {children}
        </Page>
      )}
    </>
  );
};

export default AcceptWrap;
