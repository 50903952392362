import { configureStore as createStore } from "@reduxjs/toolkit";
import appConfigReducer from "./app";
import userReducer from "./user";
import {
  TypedUseSelectorHook,
  useDispatch as dispatchHook,
  useSelector as selectorHook,
} from "react-redux";

const configureStore = () => {
  const store = createStore({
    reducer: {
      app: appConfigReducer,
      user: userReducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware();
    },
  });
  return store;
};

export const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch: () => AppDispatch = dispatchHook;
export const useSelector: TypedUseSelectorHook<RootState> = selectorHook;
