import { useDispatch, useSelector } from "../../services/store";
import { IUserSessions } from "../../utils/types";
import styles from "./ui.module.css";

import closeIcon from "../../images/close.svg";

import smartphoneIcon from "../../images/smartphone.svg";
import desktopIcon from "../../images/desktop.svg";

import deviceWin11 from "../../images/devices/win11.svg";
import deviceAndroid from "../../images/devices/android.svg";
import deviceApple from "../../images/devices/apple.svg";
import deviceLinux from "../../images/devices/linux.png";
import deviceUbuntu from "../../images/devices/ubuntu.png";
import { useState } from "react";
import PopUp from "../pop-up/pop-up";
import Button from "./button";
import { useNavigate } from "react-router-dom";
import { closeUserSession } from "../../services/actions/user";

interface IUserSessionItem {
  session: IUserSessions;
  active?: boolean;
}

const UserSessionItem = ({ session, active }: IUserSessionItem) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const [showCloseSessionPopUp, setShowCloseSessionPopUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const DeviceIcon = ({ os, type }: { os: string; type: string }) => {
    let iconAlt = os;
    let iconSrc = "";
    let canDarkMode = false;
    let size = styles.default;
    if (os.toLowerCase().includes("win") !== false) {
      iconSrc = deviceWin11;
    } else if (os.toLowerCase().includes("android") !== false) {
      iconSrc = deviceAndroid;
      size = styles.large;
    } else if (
      os.toLowerCase().includes("ios") !== false ||
      os.toLowerCase().includes("macos") !== false
    ) {
      iconSrc = deviceApple;
      size = styles.large;
      canDarkMode = true;
    } else if (os.toLowerCase().includes("linux") !== false) {
      iconSrc = deviceLinux;
      size = styles.exlarge;
    } else if (os.toLowerCase().includes("ubuntu") !== false) {
      iconSrc = deviceUbuntu;
      size = styles.large;
    } else {
      iconAlt = type;
      iconSrc = type === "Mobile Phone" ? smartphoneIcon : desktopIcon;
      canDarkMode = true;
    }
    return (
      <>
        <img
          className={`${canDarkMode ? styles.canDarkMode : ""} ${size}`}
          src={iconSrc}
          alt={iconAlt}
        />
      </>
    );
  };

  const lastActivityTime = (date: string) => {
    let Today = new Date();
    let ActivityDate = new Date(date);
    let FormattedDate;

    if (
      ActivityDate.getDate() == Today.getDate() &&
      ActivityDate.getMonth() == Today.getMonth() &&
      ActivityDate.getFullYear() == Today.getFullYear()
    ) {
      FormattedDate =
        ("0" + ActivityDate.getHours()).slice(-2) +
        ":" +
        ("0" + ActivityDate.getMinutes()).slice(-2);
    } else {
      FormattedDate =
        ("0" + ActivityDate.getDate()).slice(-2) +
        "." +
        ("0" + (ActivityDate.getMonth() + 1)).slice(-2) +
        "." +
        ActivityDate.getFullYear();
    }
    return FormattedDate;
  };

  const closeSession = () => {
    if (session) {
      dispatch(
        closeUserSession({
          session_id: session.session_id,
        })
      ).then((data) => {
        navigate("/profile");
      });
    }
  };

  const OnCloseSessionPopUpCloseHandle = () => {
    setShowCloseSessionPopUp(false);
  };
  return (
    <>
      <div
        className={`${styles.userSession} ${darkMode ? styles.darkMode : ""}`}
      >
        <div className={styles.userSessionIcon}>
          <DeviceIcon os={session.device_os} type={session.device_type} />
        </div>
        <div className={styles.userSessionInfo}>
          <div className={styles.userSessionDeviceName}>
            <img
              src={
                session.device_type === "Mobile Phone"
                  ? smartphoneIcon
                  : desktopIcon
              }
              alt={session.device_type}
            />
            <span>{session.device_name}</span>
          </div>
          <div className={styles.userSessionDeviceApp}>
            {session.device_app}
          </div>
          <div className={styles.userSessionDeviceLocation}>
            {session.device_ip} • {lastActivityTime(session.last_activity)}
          </div>
        </div>
        {!active && (
          <div className={styles.userSessionControl}>
            <img
              onClick={() => setShowCloseSessionPopUp(true)}
              src={closeIcon}
              alt="Завершить сеанс"
            />
          </div>
        )}
      </div>
      {showCloseSessionPopUp && (
        <PopUp onClose={OnCloseSessionPopUpCloseHandle}>
          <p className={`${styles.userSessionPopUpMess} ${darkMode ? styles.darkMode : ""}`}>Завершить сеанс {session?.device_name}?</p>
          <div className={styles.userSessionButtonWrap}>
            <Button onClick={closeSession} type="button" buttonStyle="danger">
              Да
            </Button>
            <Button
              onClick={() => setShowCloseSessionPopUp(false)}
              type="button"
              buttonStyle="light"
            >
              Отменить
            </Button>
          </div>
        </PopUp>
      )}
    </>
  );
};

export default UserSessionItem;
