import { useSelector } from "../../services/store";
import styles from "./ui.module.css";

interface IUserPhoto {
  size?: "big";
}

const UserPhoto = ({ size }: IUserPhoto) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const user = useSelector((state) => state.user.user);

  return (
    <div
      className={`${styles.profilePhoto} ${darkMode ? styles.darkMode : ""} ${size ? styles.big : ""}`}
    >
      {user?.name?.[0]?.toUpperCase()}
      {user?.name?.[1]?.toUpperCase()}
    </div>
  );
};

export default UserPhoto;
