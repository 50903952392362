import { useSelector } from "../../services/store";
import { IAccount, ITransactionHistory } from "../../utils/types";
import styles from "./transaction-history-element.module.css";

type FormatDateProps = {
  item: ITransactionHistory;
};

type TCurrency = string;

const TransactionHistoryElement = ({ item }: FormatDateProps) => {
  const appConfig = useSelector((state) => state.app);
  const accounts = useSelector((state) => state.user.accounts);
  const icons = useSelector((state) => state.app.icons);
  const currencies = useSelector((state) => state.app.currencies);

  const account = accounts?.find(
    (accountItem) => accountItem.id === item.account_id
  );

  const formattedCurrency = (currency_code: TCurrency) => {
    if (currencies) {
      return currencies.filter((cItem) => cItem.code === currency_code)[0]
        ?.symbol;
    } else {
      return "";
    }
  };

  const formattedSum = (sum: number): string => {
    return new Intl.NumberFormat("ru-RU").format(sum);
  };

  const formattedDate = (date: string): string => {
    let date_obj = new Date(date);
    let full_date =
      date_obj.getHours() +
      ":" +
      date_obj.getMinutes() +
      " " +
      date_obj.getDate() +
      "." +
      date_obj.getMonth() +
      "." +
      date_obj.getFullYear();
    return full_date;
  };

  const Icon = ({ item }: { item?: IAccount }) => {
    if (item) {
      if (icons && item.icon && Number(item.icon) !== 0) {
        return icons.filter((icon) => icon.id === Number(item.icon))[0]
          ?.type === "img" ? (
          <img
            className={styles.icon}
            src={icons.filter((icon) => icon.id === Number(item.icon))[0]?.url}
            alt={item.name}
          />
        ) : (
          <div className={styles.symbolIcon}>
            {icons.filter((icon) => icon.id === Number(item.icon))[0]?.symbol}
          </div>
        );
      } else {
        return (
          <div className={styles.currencyIcon}>
            {formattedCurrency(item.currency_code)}
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <div
      className={`${styles.itemWrap} ${appConfig.darkMode && styles.darkMode}`}
    >
      <div className={styles.item}>
        <Icon item={account} />
        <div className={styles.info}>
          <div className={styles.name}>
            Название
            <div className={styles.category}>Категория</div>
          </div>
          <div>
            <div
              className={`${styles.futureSum} ${
                item.type === "upcoming" ? styles.plus : styles.minus
              }`}
            >
              <span className={styles.sum}>
                {item.type === "upcoming" ? "+" : "-"}
                {formattedSum(item.sum)}
              </span>
              <span className={styles.currency}>
                {formattedCurrency(item.currency_code)}
              </span>
            </div>
            <div className={styles.date}>{formattedDate(item.date)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistoryElement;
