import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Account, Home } from "../../pages";
import Modal from "../modal/modal";
import TransactionHistory from "../transaction-history/transaction-history";
import { setDarkMode, setScrollTop } from "../../services/app";
import ExpenseForm from "../expense-form/expense-form";
import Page from "../page/page";
import { OnlyAuth, OnlyUnAuth } from "../protected-route-element";
import {
  checkUserAuth,
  getAccounts,
  getSettings,
  getTObjects,
  getTransactions,
} from "../../services/actions/user";
import { useDispatch, useSelector } from "../../services/store";
import Auth from "../auth/auth";
import AccountAddForm from "../account-add-form/account-add-form";
import AccountEditForm from "../account-edit-form/account-edit-form";
import UpcomingForm from "../upcoming-form/upcoming-form";
import TransferForm from "../transfer-form/transfer-form";
import Profile from "../../pages/profile";
import {
  getCurrencies,
  getIcons,
  getIconsCategories,
} from "../../services/actions/app-data";
import ObjectsAddForm from "../objects-add-form/objects-add-form";
import Object from "../../pages/object";
import ObjectsEditForm from "../objects-edit-form/objects-edit-form";
import Tools from "../../pages/tools";
import Categories from "../../pages/categories";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const background = location.state && location.state.background;

  const dispatch = useDispatch();

  const handleModalClose = () => {
    // Возвращаемся к предыдущему пути при закрытии модалки
    // navigate(-1);
    navigate("/");
  };
  const handleAccountModalClose = () => {
    let accountPage = location.pathname.replace("/edit", "");
    navigate(accountPage);
  };
  const handlePrevModalClose = () => {
    navigate("/tools");
  };

  const userLoaded = useSelector((state) => state.user.loaded);
  const settings = useSelector((state) => state.user.settings);

  const appConfig = useSelector((state) => state.app);

  const appRef = useRef<null | HTMLDivElement>(null);

  const scrollHandle = (event: any): void => {
    if (appRef.current) {
      let contentScrolled = appRef.current.scrollTop > 10;
      if (appConfig.contentScrolled !== contentScrolled) {
        // dispatch(setScrollTop(!appConfig.contentScrolled));
      }
    }
  };

  const setUserTheme = useCallback(
    (theme_id: number) => {
      if (theme_id === 0) {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          dispatch(setDarkMode(true));
        }
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", (event) => {
            dispatch(setDarkMode(event.matches ? true : false));
          });
      } else if (theme_id === 1) {
        dispatch(setDarkMode(false));
      } else if (theme_id === 2) {
        dispatch(setDarkMode(true));
      }
    },
    [dispatch]
  );

  const localTheme = Number(localStorage.getItem("theme"));

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      setUserTheme(localTheme);
    } else {
      localStorage.setItem("theme", "0");
      setUserTheme(0);
    }
  }, [localTheme, setUserTheme]);

  useEffect(() => {
    dispatch(checkUserAuth());
    if (userLoaded) {
      dispatch(getSettings()).then(() => {
        dispatch(getCurrencies()).then(() => {
          dispatch(getIcons()).then(() => {
            dispatch(getIconsCategories()).then(() => {
              dispatch(getAccounts()).then(() => {
                dispatch(getTransactions()).then(() => {
                  dispatch(getTObjects());
                });
              });
            });
          });
        });
      });
    }
  }, [userLoaded]);

  useEffect(() => {
    if (settings.loaded) {
      localStorage.setItem("theme", String(settings.theme));
      setUserTheme(settings.theme);
    }
  }, [dispatch, settings, setUserTheme]);

  // useEffect(() => {
  //   dispatch(setScrollTop(false));

  //   return () => {
  //     dispatch(setScrollTop(false));
  //   };
  // }, [dispatch]);

  return (
    <div
      ref={appRef}
      className={`app ${appConfig.darkMode && "dark-mode"}`}
      onScroll={scrollHandle}
    >
      <div className="main-page">
        <Routes location={background || location}>
          <Route
            index
            element={
              <>
                <OnlyAuth component={<Home />} />
                <OnlyUnAuth component={<Auth />} />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <OnlyAuth
                component={
                  <Page>
                    <Profile />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/expense/fix"
            element={
              <OnlyAuth
                component={<ExpenseForm onSubmit={handleModalClose} />}
              />
            }
          />
          <Route
            path="/upcoming/fix"
            element={
              <OnlyAuth
                component={<UpcomingForm onSubmit={handleModalClose} />}
              />
            }
          />
          <Route
            path="/transfer"
            element={
              <OnlyAuth
                component={<TransferForm onSubmit={handleModalClose} />}
              />
            }
          />
          <Route
            path="/history"
            element={
              <OnlyAuth
                component={
                  <Page title={"История операций"}>
                    <TransactionHistory />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/accounts/add"
            element={
              <OnlyAuth
                component={
                  <Page title={"Добавление счёта"}>
                    <AccountAddForm onSubmit={handleModalClose} />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/accounts/edit/:id"
            element={
              <OnlyAuth
                component={
                  <Page
                    title={"Редактирование счёта"}
                    onBack={handleAccountModalClose}
                  >
                    <AccountEditForm onSubmit={handleAccountModalClose} />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/accounts/:id"
            element={<OnlyAuth component={<Account />} />}
          />
          <Route
            path="/objects/add"
            element={
              <OnlyAuth
                component={
                  <Page title={"Добавление объекта"}>
                    <ObjectsAddForm onSubmit={handleModalClose} />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/objects/edit/:id"
            element={
              <OnlyAuth
                component={
                  <Page
                    title={"Редактирование объекта"}
                    onBack={handleAccountModalClose}
                  >
                    <ObjectsEditForm onSubmit={handleAccountModalClose} />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/objects/:id"
            element={<OnlyAuth component={<Object />} />}
          />
          <Route
            path="/tools"
            element={
              <OnlyAuth
                component={
                  <Page title={"Инструменты"}>
                    <Tools />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/categories"
            element={
              <OnlyAuth
                component={
                  <Page title={"Категории"}>
                    <Categories />
                  </Page>
                }
              />
            }
          />
        </Routes>
        {background && (
          <Routes>
            <Route
              path="/expense/fix"
              element={
                <OnlyAuth
                  component={
                    <ExpenseForm onSubmit={handleModalClose} useModal={true} />
                  }
                />
              }
            />
            <Route
              path="/upcoming/fix"
              element={
                <OnlyAuth
                  component={
                    <UpcomingForm onSubmit={handleModalClose} useModal={true} />
                  }
                />
              }
            />
            <Route
              path="/transfer"
              element={
                <OnlyAuth
                  component={
                    <TransferForm onSubmit={handleModalClose} useModal={true} />
                  }
                />
              }
            />
            <Route
              path="/history"
              element={
                <OnlyAuth
                  component={
                    <Modal
                      title={"История операций"}
                      onClose={handleModalClose}
                    >
                      <TransactionHistory />
                    </Modal>
                  }
                />
              }
            />
            <Route
              path="/accounts/add"
              element={
                <OnlyAuth
                  component={
                    <Modal
                      title={"Добавление счёта"}
                      onClose={handleModalClose}
                    >
                      <AccountAddForm onSubmit={handleModalClose} />
                    </Modal>
                  }
                />
              }
            />
            <Route
              path="/accounts/edit/:id"
              element={
                <OnlyAuth
                  component={
                    <Modal
                      title={"Редактирование счёта"}
                      onClose={handleAccountModalClose}
                    >
                      <AccountEditForm onSubmit={handleAccountModalClose} />
                    </Modal>
                  }
                />
              }
            />

            <Route
              path="/objects/add"
              element={
                <OnlyAuth
                  component={
                    <Modal
                      title={"Добавление объекта"}
                      onClose={handleModalClose}
                    >
                      <ObjectsAddForm onSubmit={handleModalClose} />
                    </Modal>
                  }
                />
              }
            />

            <Route
              path="/objects/edit/:id"
              element={
                <OnlyAuth
                  component={
                    <Modal
                      title={"Редактирование объекта"}
                      onClose={handleAccountModalClose}
                    >
                      <ObjectsEditForm onSubmit={handleAccountModalClose} />
                    </Modal>
                  }
                />
              }
            />

            <Route
              path="/tools"
              element={
                <Modal title={"Инструменты"} onClose={handleModalClose}>
                  <Tools />
                </Modal>
              }
            />
            <Route
              path="/categories"
              element={
                <Modal title={"Категории"} onClose={handlePrevModalClose}>
                  <Categories />
                </Modal>
              }
            />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
