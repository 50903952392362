import { Link, useLocation } from "react-router-dom";
import { useSelector } from "../../services/store";
import { IObjects } from "../../utils/types";
import styles from "./expense-object.module.css";

type FormatDateProps = {
  item?: IObjects;
};

const ExpenseObject = ({ item }: FormatDateProps) => {
  const appConfig = useSelector((state) => state.app);

  // type TCurrency = "RUB" | "USD";

  // const futureSumCurrency: TCurrency = "RUB";

  // const formattedSum = (sum: number): string => {
  //   return new Intl.NumberFormat("ru-RU").format(sum);
  // };

  // const formattedCurrency = (currency: TCurrency): string => {
  //   switch (currency) {
  //     case "RUB":
  //       return "₽";
  //     case "USD":
  //       return "$";
  //   }
  // };

  const location = useLocation();

  const Icon = ({ item }: { item: IObjects }) => {
    if (appConfig.icons && item.icon && Number(item.icon) !== 0) {
      return appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
        ?.type === "img" ? (
        <img
          className={styles.icon}
          src={
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.url
          }
          alt={item.name}
        />
      ) : (
        <div className={styles.symbolIcon}>
          {
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.symbol
          }
        </div>
      );
    } else {
      return <div className={styles.currencyIcon}>👽</div>;
    }
  };

  return (
    <div
      className={`${styles.itemWrap} ${appConfig.darkMode && styles.darkMode}`}
    >
      {item ? (
        <Link to={`/objects/${item.id}`} className={styles.item}>
          <Icon item={item} />
          <div>
            <div className={styles.name}>{item.name}</div>
            {/* <div className={styles.futureSum}>
              <span className={styles.sum}>0</span>
              <span className={styles.currency}></span>
            </div> */}
          </div>
        </Link>
      ) : (
        <Link
          to="/objects/add"
          state={{ background: location }}
          className={styles.addItem}
        >
          <div className={styles.name}>Добавить объект</div>
          <div className={styles.plus}>+</div>
        </Link>
      )}
    </div>
  );
};

export default ExpenseObject;
