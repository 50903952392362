import AppHeader from "../components/app-header/app-header";
import MainControlPanel from "../components/main-control-panel/main-control-panel";
import TransactionHistory from "../components/transaction-history/transaction-history";
import {
  getCurrencies,
  getIcons,
  getIconsCategories,
} from "../services/actions/app-data";
import { useEffect } from "react";
import { useDispatch } from "../services/store";
import {
  checkUserAuth,
  getAccounts,
  getSettings,
  getTransactions,
  getUser,
} from "../services/actions/user";
import AccountView from "../components/account-view/account-view";

const Account = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());

    // dispatch(getCurrencies()).then(() => {
    //   dispatch(getIcons()).then(() => {
    //     dispatch(getIconsCategories()).then(() => {
    //       dispatch(getAccounts()).then(() => {
    //         dispatch(getTransactions());
    //       });
    //     });
    //   });
    // });
  }, [dispatch]);

  return (
    <>
      <AppHeader />
      <main className="content">
        <AccountView />
        <div>
          <TransactionHistory />
        </div>
      </main>
      <MainControlPanel backlink="/" />
    </>
  );
};

export default Account;
