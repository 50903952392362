import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AccountsItem from "../accounts-item/accounts-item";

import styles from "./ui.module.css";
import AccountsSelectItem from "./accounts-select-item";
import { useEffect, useState } from "react";
import { useSelector } from "../../services/store";
import { IAccount } from "../../utils/types";

type TOptions = {
  filter: {
    currency: string;
    selectedAccount: string;
  };
};

interface IAccountsSelect {
  selected?: string;
  onSelect: (id: IAccount["id"]) => void;
  className?: string;
  name?: string;
  options?: TOptions;
}

const AccountsSelect = ({
  className,
  selected,
  onSelect,
  name = "",
  options,
}: IAccountsSelect) => {
  const userData = useSelector((state) => state.user);

  const [accounts, setAcounts] = useState<IAccount[] | null>(null);

  useEffect(() => {
    if (userData.accounts) {
      if (options && options.filter) {
        let filteredAccounts = userData.accounts;

        filteredAccounts = filteredAccounts.filter(
          (item) =>
            options.filter.currency &&
            item.currency_code === options.filter.currency &&
            options.filter.selectedAccount &&
            item.id !== options.filter.selectedAccount
        );

        setAcounts(filteredAccounts);
      } else {
        setAcounts(userData.accounts);
      }
    }
  }, [options, userData.accounts]);

  return (
    <div className={`${styles.accountsItems} ${className ? className : ""}`}>
      <Swiper slidesPerView={3} spaceBetween={-19} className={styles.swiper}>
        {accounts &&
          accounts.map((item, index) => (
            <SwiperSlide key={index}>
              <AccountsSelectItem
                selected={selected}
                onSelect={onSelect}
                item={item}
                name={name}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default AccountsSelect;
