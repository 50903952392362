import { ChangeEvent, useState } from "react";
import Button from "../components/ui/button";
import InputText from "../components/ui/input-text";
import Label from "../components/ui/label";
import UserPhoto from "../components/ui/user-photo";
import { logout, setTheme } from "../services/actions/user";
import { useDispatch, useSelector } from "../services/store";
import UserSessionItem from "../components/ui/user-session-item";
import InputRadio from "../components/ui/input-radio";

const Profile = () => {
  const user = useSelector((state) => state.user.user);
  const sessions = useSelector((state) => state.user.sessions);
  const theme = useSelector((state) => state.user.settings.theme);

  const [userTheme, setUserTheme] = useState(theme);

  const [userName, setUserName] = useState(user?.name);
  const [userEmail, setUserEmail] = useState(user?.email);

  const [userOldPassword, setUserOldPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");

  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  const themeChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "dark") {
      dispatch(setTheme(2));
      setUserTheme(2);
      localStorage.setItem("theme", String(2));
    } else if (e.target.value === "light") {
      dispatch(setTheme(1));
      setUserTheme(1);
      localStorage.setItem("theme", String(1));
    } else if (e.target.value === "system") {
      dispatch(setTheme(0));
      setUserTheme(0);
      localStorage.setItem("theme", String(0));
    }
  };

  return (
    <>
      {user && (
        <>
          <div className="d-flex justify-content-center mb-16">
            <UserPhoto size="big" />
          </div>

          <div className="default-container">
            <h2 className="h2">Личные данные</h2>
            <Label>Имя</Label>
            <InputText
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <Label>E-mail</Label>
            <InputText
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <div className="mt-4">
              <Button>Сохранить</Button>
            </div>
          </div>

          <div className="default-container">
            <h2 className="h2">Сменить пароль</h2>
            <Label>Старый пароль</Label>
            <InputText
              value={userOldPassword}
              onChange={(e) => setUserOldPassword(e.target.value)}
            />
            <Label>Новый пароль</Label>
            <InputText
              value={userNewPassword}
              onChange={(e) => setUserNewPassword(e.target.value)}
            />
            <div className="mt-4">
              <Button>Сохранить</Button>
            </div>
          </div>

          <div className="default-container">
            <h2 className="h2">Настройки</h2>
            <Label>Тема</Label>
            <InputRadio
              name="theme"
              label="Светлая"
              value="light"
              checked={userTheme === 1}
              onChange={themeChangeHandle}
            />
            <InputRadio
              name="theme"
              label="Тёмная"
              value="dark"
              checked={userTheme === 2}
              onChange={themeChangeHandle}
            />
            <InputRadio
              name="theme"
              label="Как в системе"
              value="system"
              checked={userTheme === 0}
              onChange={themeChangeHandle}
            />
          </div>

          <div className="default-container">
            <h2 className="h2">Активные сеансы</h2>
            {sessions && sessions.length > 1 && <Label>Это устройтсво</Label>}
            <div>
              {sessions &&
                sessions
                  .filter((item) => item.is_active === true)
                  .map((session, index) => (
                    <UserSessionItem
                      key={index}
                      session={session}
                      active={true}
                    />
                  ))}
            </div>
            {sessions && sessions.length > 1 && (
              <>
                <Label>Активные сеансы</Label>
                <div>
                  {sessions &&
                    sessions
                      .filter((item) => item.is_active === false)
                      .map((session, index) => (
                        <UserSessionItem key={index} session={session} />
                      ))}
                </div>
              </>
            )}
          </div>

          <div className="default-container">
            <div className="d-flex justify-content-center mt-4">
              <Button buttonStyle="light" onClick={onLogout}>
                Выйти
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
