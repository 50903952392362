import React, { useEffect } from "react";
import styles from "./page.module.css";
import gobackSvg from "../../images/goback.svg";
import acceptSvg from "../../images/accept.svg";
import gobackLightSvg from "../../images/goback-light.svg";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "../../services/store";
import {
  getCurrencies,
  getIcons,
  getIconsCategories,
} from "../../services/actions/app-data";
import {
  checkUserAuth,
  getAccounts,
  getSettings,
  getTransactions,
  getUser,
} from "../../services/actions/user";

interface IPage {
  children: React.ReactNode;
  title?: string | undefined;
  onBack?: () => void;
  onAccept?: () => void;
  useAcceptButton?: boolean;
}

const Page = ({
  children,
  title,
  onBack,
  useAcceptButton,
  onAccept,
}: IPage) => {
  const appConfig = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
    // dispatch(getCurrencies()).then(() => {
    //   dispatch(getIcons()).then(() => {
    //     dispatch(getIconsCategories()).then(() => {
    //       dispatch(getAccounts()).then(() => {
    //         dispatch(getTransactions());
    //       });
    //     });
    //   });
    // });
  }, [dispatch]);

  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
  };

  return (
    <div
      className={`${styles.modal} ${appConfig.darkMode ? styles.darkMode : ""}`}
    >
      {title && (
        <header
          className={`${styles.header} ${
            appConfig.contentScrolled ? styles.scrolled : ""
          }`}
        >
          <h3 className={styles.title}>{title}</h3>
        </header>
      )}
      <div className={styles.content}>{children}</div>
      <footer className={styles.footer}>
        {onBack ? (
          <div onClick={onBack} className={`${styles.goBack} cursor-pointer place-self-center grid-column-2`}>
            <img
              src={appConfig.darkMode ? gobackLightSvg : gobackSvg}
              alt="go back"
            />
          </div>
        ) : (
          <NavLink to="/" className={`${styles.goBack} cursor-pointer place-self-center grid-column-2`}>
            <img
              src={appConfig.darkMode ? gobackLightSvg : gobackSvg}
              alt="go back"
            />
          </NavLink>
        )}
        {useAcceptButton && (
          <span
            className={`${styles.accept} cursor-pointer place-self-center`}
            onClick={handleAccept}
          >
            <img src={acceptSvg} alt="accept" />
          </span>
        )}
      </footer>
    </div>
  );
};

export default Page;
