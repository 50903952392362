import React, { MouseEvent, useEffect } from "react";

import styles from "./modal-overlay.module.css";
import { setScrollTop } from "../../services/app";
import { useDispatch, useSelector } from "../../services/store";

type TModalOverlayProps = {
  onClose: () => void;
  children: React.ReactNode;
};

const ModalOverlay = ({ onClose, children }: TModalOverlayProps) => {
  const appConfig = useSelector((state) => state.app);

  const overlayRef = React.useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === overlayRef.current) {
      // onClose();
    }
  };

  const scrollHandle = () => {
    if (overlayRef.current) {
      let contentScrolled = overlayRef.current.scrollTop > 10;
      if (appConfig.contentScrolled !== contentScrolled) {
        // dispatch(setScrollTop(!appConfig.contentScrolled));
      }
    }
  };

  // useEffect(() => {
  //   dispatch(setScrollTop(false));

  //   return () => dispatch(setScrollTop(false));
  // }, [dispatch]);

  return (
    <div
      ref={overlayRef}
      onScroll={scrollHandle}
      onClick={handleClick}
      className={`${styles.overlay} ${appConfig.darkMode && styles.darkMode}`}
    >
      {children}
    </div>
  );
};

export default ModalOverlay;
