import { ChangeEvent, useEffect, useRef } from "react";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";

interface ITextarea {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  inFocus?: boolean;
}

const Textarea = ({ value, onChange, inFocus }: ITextarea) => {
  const darkMode = useSelector((state) => state.app.darkMode);

  const textareaRef = useRef<null | HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      if (inFocus) {
        textareaRef.current.focus();
      }
    }
  }, [inFocus]);

  return (
    <textarea
      ref={textareaRef}
      className={`${styles.textarea} ${darkMode ? styles.darkMode : ""}`}
      value={value}
      onChange={onChange}
    ></textarea>
  );
};

export default Textarea;
