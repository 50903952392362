import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../services/store";
import { addAccount, deleteAccount } from "../../services/actions/user";
import Label from "../ui/label";
import InputText from "../ui/input-text";
import InputSum from "../ui/input-sum";
import CurrencyControl from "../ui/currency-control";
import IconControl from "../ui/icon-control";
import styles from "./account-edit-form.module.css";
import Textarea from "../ui/textarea";
import Button from "../ui/button";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "../pop-up/pop-up";

interface IAccountEditForm {
  onSubmit: () => void;
}

const AccountEditForm = ({ onSubmit }: IAccountEditForm) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const accounts = useSelector((state) => state.user.accounts);

  const { id } = useParams();
  const navigate = useNavigate();

  const account = accounts?.find((item) => item.id === id);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState("");
  const [activeCurrencyCode, setActiveCurrencyCode] = useState("");
  const [currentIconId, setCurrentIconId] = useState(0);
  const [activeIconId, setActiveIconId] = useState(0);
  const [balance, setBalance] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [notes, setNotes] = useState("");
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  useEffect(() => {
    if (account) {
      setName(account.name);
      setCurrentCurrencyCode(account.currency_code);
      setActiveCurrencyCode(account.currency_code);
      setCurrentIconId(Number(account.icon));
      setActiveIconId(Number(account.icon));
      setCurrentBalance(account.balance);
      if (account.notes) {
        setNotes(account.notes);
      }
    }
  }, [account]);

  const nameChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const notesChangeEvent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (account) {
      dispatch(
        addAccount({
          id: account.id,
          name: name,
          currency_code: activeCurrencyCode,
          icon: activeIconId,
          balance: balance,
          notes: notes,
        })
      ).then((data) => {
        onSubmit();
      });
    }
  };

  const deleteAcc = () => {
    if (account) {
      dispatch(
        deleteAccount({
          id: account.id,
        })
      ).then((data) => {
        navigate("/");
      });
    }
  };

  const OnDeletePopUpCloseHandle = () => {
    setShowDeletePopUp(false);
  };
  return (
    <div className={`${styles.container} ${darkMode ? styles.darkMode : ""}`}>
      <form className={styles.form} onSubmit={onSubmitForm}>
        <Label>Название</Label>
        <InputText value={name} onChange={nameChangeEvent} />
        <Label>Баланс</Label>
        <InputSum value={currentBalance} sumChange={setBalance} />
        <Label>Валюта</Label>
        <CurrencyControl
          activeCurrencyCode={activeCurrencyCode}
          currentCurrencyCode={currentCurrencyCode}
          setActiveCurrencyCode={setActiveCurrencyCode}
        />
        <Label>Иконка</Label>
        <IconControl
          currentIconId={currentIconId}
          activeIconId={activeIconId}
          setActiveIconId={setActiveIconId}
          activeCurrencyCode={activeCurrencyCode}
        />
        <Label>Заметки</Label>
        <Textarea value={notes} onChange={notesChangeEvent} />
        <div className={styles.buttonWrap}>
          <Button>Сохранить</Button>
          <Button
            onClick={() => setShowDeletePopUp(true)}
            type="button"
            buttonStyle="danger"
          >
            Удалить счёт
          </Button>
          {showDeletePopUp && (
            <PopUp onClose={OnDeletePopUpCloseHandle}>
              <p>
                Удалить счёт {account?.name}, включая историю всех транзакций?
              </p>
              <div className={styles.buttonWrap}>
                <Button onClick={deleteAcc} type="button" buttonStyle="danger">
                  Да
                </Button>
                <Button
                  onClick={() => setShowDeletePopUp(false)}
                  type="button"
                  buttonStyle="light"
                >
                  Отменить
                </Button>
              </div>
            </PopUp>
          )}
        </div>
      </form>
    </div>
  );
};

export default AccountEditForm;
