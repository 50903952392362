import { useSelector } from "../../services/store";
import TransactionHistoryElement from "../transaction-history-element/transaction-history-element";
import styles from "./transaction-history.module.css";
import { Link, useLocation, useParams } from "react-router-dom";

type TTransactionHistory = {
  max?: number;
};

const TransactionHistory = ({ max }: TTransactionHistory) => {
  const appConfig = useSelector((state) => state.app);
  const transactions = useSelector((state) => state.user.transactions);

  const { id } = useParams();

  const location = useLocation();

  var all_transactions = transactions;

  if (id && transactions) {
    all_transactions = transactions.filter((item) => item.account_id === id);
  }

  if (max && all_transactions) {
    all_transactions = all_transactions.slice(0, max);
  }

  return (
    <>
      {all_transactions && (
        <>
          <div className="d-flex align-items-center justify-content-between block-title">
            <h5>История</h5>
            {all_transactions.length > 2 && <Link to="/">Все операции</Link>}
          </div>
          <div
            className={`${styles.itemsWrap} ${
              appConfig.darkMode && styles.darkMode
            }`}
          >
            {all_transactions.length > 0 ? (
              <Link
                to="/history"
                state={{ background: location }}
                className={styles.items}
              >
                {all_transactions.map((item) => (
                  <TransactionHistoryElement key={item.id} item={item} />
                ))}
              </Link>
            ) : (
              <div className="no-transactions">Нет операции</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TransactionHistory;
