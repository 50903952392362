import { ChangeEvent, useEffect, useRef } from "react";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";

interface IInputText {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inFocus?: boolean;
  placeholder?: string;
}

const InputText = ({ value, onChange, inFocus, placeholder }: IInputText) => {
  const darkMode = useSelector((state) => state.app.darkMode);

  const inputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      if (inFocus) {
        inputRef.current.focus();
      }
    }
  }, [inFocus]);

  return (
    <input
      ref={inputRef}
      type="text"
      className={`${styles.input} ${darkMode ? styles.darkMode : ""}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default InputText;
