import Cookies from "universal-cookie";
import { fetchWithRefresh } from "./user-api";

export const BURGER_API_URL = "https://api.finiki.app";

const getCurrencies = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/currencies`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });

const getIcons = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/icon`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });

const getIconsCategories = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/icons-categories`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });

const getCategoriesSets = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/categories-sets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });

export const api = {
  getCurrencies,
  getIcons,
  getIconsCategories,
  getCategoriesSets,
};
