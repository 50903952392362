import React, { useRef } from "react";
import ReactDOM from "react-dom";
import ModalOverlay from "../modal-overlay/modal-overlay";
import styles from "./modal.module.css";
import gobackSvg from "../../images/goback.svg";
import acceptSvg from "../../images/accept.svg";
import gobackLightSvg from "../../images/goback-light.svg";
import { useSelector } from "../../services/store";

const modalRoot = document.getElementById("react-modals") as Element;

type TModalProps = {
  onClose: () => void;
  onAccept?: () => void;
  useAcceptButton?: boolean;
  title?: string;
  children: React.ReactNode | React.ReactNode[];
};

const Modal = (props: TModalProps) => {
  const appConfig = useSelector((state) => state.app);
  const handelCloseModal = () => {
    props.onClose();
  };

  const closeByKeyEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      props.onClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", closeByKeyEsc, false);

    return () => {
      document.removeEventListener("keydown", closeByKeyEsc, false);
    };
  });

  const handleAccept = () => {
    if (props.onAccept) {
      props.onAccept();
    }
  }

  return ReactDOM.createPortal(
    <ModalOverlay onClose={props.onClose}>
      <div
        className={`${styles.modal} ${
          appConfig.darkMode ? styles.darkMode : ""
        }`}
      >
        <header
          className={`${styles.header} ${
            appConfig.contentScrolled ? styles.scrolled : ""
          }`}
        >
          <h3 className={styles.title}>{props.title}</h3>
        </header>
        <div className={styles.content}>{props.children}</div>
        <footer className={styles.footer}>
          <span
            className={`${styles.goBack} cursor-pointer place-self-center grid-column-2`}
            onClick={handelCloseModal}
          >
            <img
              src={appConfig.darkMode ? gobackLightSvg : gobackSvg}
              alt="go back"
            />
          </span>
          {props.useAcceptButton && (
            <span className={`${styles.accept} cursor-pointer place-self-center`} onClick={handleAccept}>
              <img src={acceptSvg} alt="accept"/>
            </span>
          )}
        </footer>
      </div>
    </ModalOverlay>,
    modalRoot
  );
};

export default Modal;
