import React, { ChangeEvent } from "react";
import { register as registerAction } from "../services/actions/user";
import { useDispatch, useSelector } from "../services/store";

interface ISignup {
  // setActiveCard: React.Dispatch<React.SetStateAction<number>> | undefined;
  setActiveCard: (id: number) => void | undefined;
}

const Signup = ({ setActiveCard }: ISignup) => {
  const user = useSelector((state) => state.user);

  const [nameValue, setNameValue] = React.useState("");
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  };

  const [phoneValue, setPhoneValue] = React.useState("");
  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneValue(e.target.value);
  };
  const [emailValue, setEmailValue] = React.useState("");
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const [passwordValue, setPasswordValue] = React.useState("");
  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
  };

  const dispatch = useDispatch();

  const onRegister = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      registerAction({
        username: phoneValue,
        password: passwordValue,
        email: emailValue,
        phone: phoneValue,
        name: nameValue,
      })
    ).then((data) => {
      console.log(data);
    });
  };

  return (
    <>
      <div className="logotype-text sm">
        Finiki
      </div>
      <h2 className="h2">Создание аккаунта</h2>
      <div className="logo-photo-block">
        <div className="photo-wrap">
          <div className="photo">?</div>
        </div>
        <div className="login-info"></div>
      </div>
      <form onSubmit={onRegister} className="form-auth">
        <div className="d-flex flex-direction-column">
          <input
            onChange={onNameChange}
            value={nameValue}
            name={"name"}
            placeholder="Ваше имя"
            className="input-default"
            autoComplete="name"
          />
          <input
            onChange={onPhoneChange}
            value={phoneValue}
            name={"phone"}
            placeholder="Номер телефона"
            className="input-default"
            autoComplete="phone"
          />
          <input
            onChange={onEmailChange}
            value={emailValue}
            name={"email"}
            placeholder="Почта"
            className="input-default"
            autoComplete="email"
          />
          <input
            onChange={onPasswordChange}
            value={passwordValue}
            name={"password"}
            type="password"
            placeholder="Пароль"
            className="input-default"
          />
          <div className="d-flex mt-3">
            <button type="submit" className="button-default mr-3">
              Продолжить
            </button>
            <span
              onClick={() =>
                typeof setActiveCard !== "undefined" && setActiveCard(1)
              }
              className="button-default button-light"
            >
              Отмена
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default Signup;
