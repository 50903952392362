import React, { ChangeEvent, useEffect, useRef } from "react";
import { useSelector } from "../services/store";

interface ILogin {
  // setActiveCard: React.Dispatch<React.SetStateAction<number>> | undefined;
  setActiveCard: (id: number) => void | undefined;
  section: string;
  preloginName?: string;
  usernameValue?: string;
  setUsernameValue?: React.Dispatch<React.SetStateAction<string>>;
  passwordValue?: string;
  setPasswordValue?: React.Dispatch<React.SetStateAction<string>>;
  onCheckEmail?: (e: ChangeEvent<HTMLFormElement>) => void;
  onLogin?: (e: ChangeEvent<HTMLFormElement>) => void;
  error?: string;
}

const Login = ({
  setActiveCard,
  section,
  preloginName,
  usernameValue,
  setUsernameValue,
  onCheckEmail,
  passwordValue,
  setPasswordValue,
  onLogin,
  error,
}: ILogin) => {
  const user = useSelector((state) => state.user);

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setUsernameValue) {
      setUsernameValue(e.target.value);
    }
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setPasswordValue) {
      setPasswordValue(e.target.value);
    }
  };

  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, []);

  return (
    <>
      {section === "email" && (
        <>
          <div className="logotype-text">
            Finiki
          </div>
          <form onSubmit={onCheckEmail} className="form-auth">
            <input
              onChange={onUsernameChange}
              value={usernameValue}
              name={"email"}
              placeholder="E-mail"
              className="input-default w-100"
              autoComplete="email"
              required
            />
            <button type="submit" className="button-default mt-3">
              Войти
            </button>
            <div className="pt-5 text-center">
              <span
                onClick={() =>
                  typeof setActiveCard !== "undefined" && setActiveCard(3)
                }
                className="link-default ml-3 mr-3"
              >
                Создать аккаунт
              </span>
            </div>
          </form>
        </>
      )}
      {section === "password" && (
        <>
          <div className="logotype-text sm">
            Finiki
          </div>
          <div className="logo-photo-block">
            <div className="photo-wrap">
              <div className="photo">
                {preloginName?.[0]?.toUpperCase()}
                {preloginName?.[1]?.toUpperCase()}
              </div>
            </div>
            <div className="login-info">{usernameValue}</div>
          </div>
          <form onSubmit={onLogin} className="form-auth">
            <input
              ref={passwordRef}
              onChange={onPasswordChange}
              value={passwordValue}
              name={"password"}
              type="password"
              placeholder="Введите пароль"
              className="input-default w-100"
              required
            />
            <div className="d-flex mt-3">
              <button type="submit" className="button-default mr-3">
                Войти
              </button>
              <span
                onClick={() =>
                  typeof setActiveCard !== "undefined" && setActiveCard(1)
                }
                className="button-default button-light"
              >
                Отмена
              </span>
            </div>
          </form>
        </>
      )}
      {error && <p className="error-default">{error}</p>}
    </>
  );
};

export default Login;
