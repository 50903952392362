import { useDispatch, useSelector } from "../services/store";
import widgetsPng from "../images/widgets.png";
import { Swiper, SwiperSlide } from "swiper/react";
import InputText from "../components/ui/input-text";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { checkUserAuth } from "../services/actions/user";
import { getCategoriesSets } from "../services/actions/app-data";
import { ICategoriesSet, ICategory } from "../utils/types";
import Button from "../components/ui/button";
import { Link } from "react-router-dom";
import plusSvg from "../images/plus.svg";

const Categories = () => {
  const appConfig = useSelector((state) => state.app);

  const userLoaded = useSelector((state) => state.user.loaded);

  const dispatch = useDispatch();

  const categories: (ICategory | null)[] = [
    {
      name: "Автомобиль",
      icon: 11,
    },
    {
      name: "Дом",
      icon: 12,
    },
    {
      name: "Здоровье",
    },
    {
      name: "Личные расходы",
    },
    {
      name: "Одежда",
    },
    {
      name: "Продукты",
    },
    {
      name: "Семейные расходы",
    },
    {
      name: "Техника",
    },
    {
      name: "Услуги",
    },
    {
      name: "Развлечения",
    },
    {
      name: "Путешествия",
    },
    {
      name: "Автомобиль",
      icon: 11,
    },
    {
      name: "Дом",
      icon: 12,
    },
    {
      name: "Здоровье",
    },
    {
      name: "Личные расходы",
    },
    {
      name: "Одежда",
    },
    {
      name: "Продукты",
    },
    {
      name: "Семейные расходы",
    },
    {
      name: "Техника",
    },
    {
      name: "Услуги",
    },
    {
      name: "Развлечения",
    },
    {
      name: "Путешествия",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const [searchedItems, setSearchedItems] =
    useState<(ICategory | null)[]>(categories);

  useEffect(() => {
    dispatch(checkUserAuth());
    if (userLoaded) {
      dispatch(getCategoriesSets());
    }
  }, [userLoaded]);

  const recommendedCategories = useSelector(
    (state) => state.app.categoriesSets
  );

  const Icon = ({ item }: { item: ICategoriesSet | ICategory }) => {
    if (appConfig.icons && item.icon && Number(item.icon) !== 0) {
      return appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
        ?.type === "img" ? (
        <img
          className="categories-slider-item-icon"
          src={
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.url
          }
          alt={item.name}
        />
      ) : (
        <div className="categories-slider-item-symbolIcon">
          {
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.symbol
          }
        </div>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (categories && categories.length > 0) {
      if (searchValue.length > 0) {
        setSearchedItems(
          categories.filter((item) =>
            item?.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setSearchedItems(categories);
      }
    }
  }, [searchValue]);

  const onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={appConfig.darkMode ? "darkMode" : ""}>
      <div className="d-flex align-items-center justify-content-between categories-search-block pl-4 pr-4">
        <InputText
          value={searchValue}
          onChange={onSearchInput}
          placeholder="Поиск"
        />
        <Link to="/" className="orange ml-3">
          <img src={plusSvg} alt="plus" />
        </Link>
      </div>
      <div className="pt-4">
        {categories && categories.length > 0 ? (
          <div className="h-limit-290 pl-4 pr-4">
            <div className="pb-5">
              {searchedItems.length > 0 ? (
                searchedItems.map((item, index) => (
                  <div key={index} className="d-flex align-items-center c-gray">
                    {item?.icon ? (
                      <Icon item={item} />
                    ) : (
                      <div className="categories-slider-item-icon"></div>
                    )}
                    {item?.name}
                  </div>
                ))
              ) : (
                <div className="pl-4 pr-4 pt-10 pb-10 text-center c-gray">
                  Ничего не найдено
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="pl-4 pr-4 pt-20 pb-20 text-center c-gray">
            <p className="m-0 pl-4 pr-4 mb-5 categories-welcoming-text">
              Создайте свои категории расходов и доходов или выберите готовый
              набор.
            </p>
          </div>
        )}
        {recommendedCategories && (
          <div className="default-categories pl-2 pr-2">
            <h5 className="mb-4 pl-4 pr-4">Готовые наборы категорий</h5>
            <div className=" pt-4 pb-4 ">
              <div className="pl-4 pr-4">
                {recommendedCategories.map((item, index) => (
                  <div key={index} className="d-flex align-items-center">
                    {item?.icon ? (
                      <Icon item={item} />
                    ) : (
                      <div className="categories-slider-item-icon"></div>
                    )}
                    {item?.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Categories;
