import { useSelector } from "../services/store";
import widgetsPng from "../images/widgets.png";
import { Link, useLocation } from "react-router-dom";

const Tools = () => {
  const appConfig = useSelector((state) => state.app);

  const location = useLocation();

  return (
    <div className={appConfig.darkMode ? "darkMode" : ""}>
      <div className="tool-banners">
        <div className="tool-banner bg-blue grid-col-start-1 grid-col-end-3">
          <div className="tool-banner-info">
            <h5 className="mb-2">Виджеты в шапке</h5>
            <p className="m-0">
              Настройте отображение финансовых данных в шапке
            </p>
          </div>
          <div className="tool-banner-icon">
            <img src={widgetsPng} alt="Widgets" />
          </div>
        </div>
        <div className="tool-banner bg-green grid-col-start-1 grid-col-end-2">
          <div className="tool-banner-info">
            <h5 className="mb-2">Графики</h5>
            <p className="m-0">
              Создавайте графики и отслеживайте показатели ваших финансов
            </p>
          </div>
        </div>
        <Link
          to="/categories"
          className="tool-banner bg-orange grid-col-start-2 grid-col-end-3"
          state={{ background: location }}
        >
          <div className="tool-banner-info">
            <h5 className="mb-2">Категории можно настроить тут</h5>
            <p className="m-0">Создавайте свои категории расходов и доходов</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Tools;
