import styles from "./main-control-panel.module.css";
import transferSvg from "../../images/transfer.svg";
import toolsSvg from "../../images/widgets.svg";
import plusSvg from "../../images/plus.svg";
import minusSvg from "../../images/minus.svg";
import { Link, useLocation } from "react-router-dom";
import gobackSvg from "../../images/goback.svg";
import gobackLightSvg from "../../images/goback-light.svg";
import { useSelector } from "../../services/store";

interface IMainControlPanel {
  backlink?: string;
}

const MainControlPanel = ({ backlink }: IMainControlPanel) => {
  const appConfig = useSelector((state) => state.app);

  const location = useLocation();

  return (
    <>
      <div
        className={`${styles.panel} ${appConfig.darkMode && styles.darkMode}`}
      >
        <div className="d-flex place-self-center">
          {backlink ? (
            <Link to={backlink} className={`${styles.goBack} cursor-pointer`}>
              <img
                src={appConfig.darkMode ? gobackLightSvg : gobackSvg}
                alt="go back"
              />
            </Link>
          ) : (
            <Link
              to={`/tools`}
              state={{ background: location }}
              className={`${styles.widget} cursor-pointer`}
            >
              <img src={toolsSvg} alt="tools" />
            </Link>
          )}
        </div>
        <Link
          to={`/transfer`}
          state={{ background: location }}
          className={`${styles.transfer} place-self-center`}
        >
          <img src={transferSvg} alt="transfer" />
        </Link>
        <div className="d-flex place-self-center">
          <Link
            to={`/upcoming/fix`}
            state={{ background: location }}
            className={`${styles.plus}`}
          >
            <img src={plusSvg} alt="plus" />
          </Link>
          <Link
            to={`/expense/fix`}
            state={{ background: location }}
            className={`${styles.minus} ml-5`}
          >
            <img src={minusSvg} alt="minus" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default MainControlPanel;
