import { useSelector } from "../../services/store";
import styles from "./ui.module.css";

interface ILabel {
  children: React.ReactNode | React.ReactNode[];
}

const Label = ({ children }: ILabel) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  return (
    <label className={`${styles.label} ${darkMode ? styles.darkMode : ""}`}>
      {children}
    </label>
  );
};

export default Label;
