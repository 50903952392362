import styles from "./widgets.module.css";

type TBalanceWidget = {
  sum: number;
  currency: TCurrency;
  title: string;
};

type TCurrency = "RUB" | "USD";

const BalanceWidget = ({ sum, currency, title }: TBalanceWidget) => {
  const formattedSum = (sum: number): string => {
    return new Intl.NumberFormat("ru-RU").format(sum);
  };

  const formattedCurrency = (currency: TCurrency): string => {
    switch (currency) {
      case "RUB":
        return "₽";
      case "USD":
        return "$";
    }
  };

  return (
    <div className={styles.widgetWrap}>
      <div className={styles.currentSum}>
        <span className={styles.sum}>{formattedSum(sum)}</span>
        <span className={styles.currency}>{formattedCurrency(currency)}</span>
      </div>
      <div className={styles.futureSum}>
        <span className={styles.period}>{title}</span>
      </div>
      </div>
  );
};

export default BalanceWidget;
