import {
  TAccountAddParams,
  TAccountDeleteParams,
  TTransactionAddParams,
  TUserForgotPasswordParams,
  TUserLoginParams,
  TUserPreloginParams,
  TUserRegisterParams,
  TUserResetPasswordParams,
  TUserUpdateParams,
  ResponseError,
  TCloseUserSessionParams,
  TObjectAddParams,
  TObjectDeleteParams,
} from "./types";

import Cookies from "universal-cookie";

export const BURGER_API_URL = "https://api.finiki.app";

const checkReponse = (res: Response) => {
  return res.ok
    ? res.json()
    : res.json().then((err: Error) => Promise.reject(err));
};

export const refreshToken = () => {
  return fetch(`${BURGER_API_URL}/v1/auth/token`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  }).then(checkReponse);
};

export const fetchWithRefresh = async (url: string, options: any) => {
  try {
    const res: Response = await fetch(url, options);
    return await checkReponse(res);
  } catch (err) {
    const rs = err as ResponseError;
    if (rs.message === "the access - token expired ") {
      const refreshData = await refreshToken(); //обновляем токен
      if (!refreshData.success) {
        return;
        // return Promise.reject(refreshData);
      }

      const cookies = new Cookies(null, { path: "/" });
      cookies.set("accessToken", refreshData.accessToken);

      options.headers.Authorization = refreshData.accessToken;
      const res = await fetch(url, options); //повторяем запрос
      return await checkReponse(res);
    }
  }
};

function request(url: string, options: {}) {
  return fetch(url, options).then((res) => {
    return checkReponse(res);
  });
}

const getUser = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/auth/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });

const update = (params: TUserUpdateParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/auth/user`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });

const forgotPassword = (params: TUserForgotPasswordParams) =>
  request(`${BURGER_API_URL}/v1/password-reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(params),
  });

const resetPassword = (params: TUserResetPasswordParams) =>
  request(`${BURGER_API_URL}/v1/password-reset/reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(params),
  });

const prelogin = (params: TUserPreloginParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/auth/pre-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(params),
  });

const login = (params: TUserLoginParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(params),
  });

const register = (params: TUserRegisterParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/auth/register`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(params),
  });

const logout = () =>
  request(`${BURGER_API_URL}/v1/auth/logout`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });

const getAccounts = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/accounts`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });
const addAccount = (params: TAccountAddParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/accounts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });
const deleteAccount = (params: TAccountDeleteParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/accounts`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });

const getTObjects = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/objects`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });
const addTObject = (params: TObjectAddParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/objects`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });
const deleteTObject = (params: TObjectDeleteParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/objects`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });

const closeUserSession = (params: TCloseUserSessionParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/settings/close-session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });

const getTransactions = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/transactions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });
const addTransaction = (params: TTransactionAddParams) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/transactions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify(params),
  });

const getSettings = () =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/settings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
  });
const setTheme = (params: 0 | 1 | 2) =>
  fetchWithRefresh(`${BURGER_API_URL}/v1/settings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: new Cookies(null, { path: "/" }).get("accessToken"),
    },
    body: JSON.stringify({ theme: params }),
  });

export const api = {
  getUser,
  prelogin,
  login,
  register,
  logout,
  update,
  forgotPassword,
  resetPassword,
  getAccounts,
  addAccount,
  deleteAccount,
  getTObjects,
  addTObject,
  deleteTObject,
  closeUserSession,
  getTransactions,
  addTransaction,
  getSettings,
  setTheme,
};
