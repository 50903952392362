import styles from "./accounts.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AccountsItem from "../accounts-item/accounts-item";
import { useSelector } from "../../services/store";

const Accounts = () => {
  const userData = useSelector((state) => state.user);

  return (
    <div className={styles.items}>
      <Swiper slidesPerView={3} spaceBetween={-19} className={styles.swiper}>
        {userData.accounts &&
          userData.accounts.map((item, index) => (
            <SwiperSlide key={index}>
              <AccountsItem item={item} />
            </SwiperSlide>
          ))}
        <SwiperSlide key={0}>
          <AccountsItem />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Accounts;
