import styles from "./ui.module.css";
import { ChangeEvent } from "react";
import { useSelector } from "../../services/store";
import { IAccount } from "../../utils/types";

type TCurrency = string;

type FormatDateProps = {
  item?: IAccount;
  selected?: string;
  onSelect: (id: IAccount["id"]) => void;
  name?: string;
};

const AccountsSelectItem = ({
  item,
  selected,
  onSelect,
  name,
}: FormatDateProps) => {
  const appConfig = useSelector((state) => state.app);

  const formattedSum = (sum: number): string => {
    return new Intl.NumberFormat("ru-RU").format(sum);
  };

  const onAccountSelect = (e: ChangeEvent<HTMLInputElement>) => {
    onSelect(e.target.value);
  };

  const formattedCurrency = (currency_code: TCurrency) => {
    if (appConfig.currencies) {
      return appConfig.currencies.filter(
        (item) => item.code === currency_code
      )[0]?.symbol;
    } else {
      return "";
    }
  };

  const Icon = ({ item }: { item: IAccount }) => {
    if (appConfig.icons && item.icon && Number(item.icon) !== 0) {
      return appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
        ?.type === "img" ? (
        <img
          className={styles.accountIcon}
          src={
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.url
          }
          alt={item.name}
        />
      ) : (
        <div className={styles.accountSymbolIcon}>
          {
            appConfig.icons.filter((icon) => icon.id === Number(item.icon))[0]
              ?.symbol
          }
        </div>
      );
    } else {
      return (
        <div className={styles.accountCurrencyIcon}>
          {formattedCurrency(item.currency_code)}
        </div>
      );
    }
  };

  return (
    <label
      className={`${styles.accountItemWrap} ${
        appConfig.darkMode && styles.darkMode
      }`}
    >
      {item && (
        <>
          <input
            type="radio"
            name={name ? name : "account"}
            value={item.id}
            checked={selected && selected === item.id ? true : false}
            onChange={onAccountSelect}
          />
          <div className={styles.accountItem}>
            <div className={styles.accountName}>{item.name}</div>
            <div className="d-flex align-items-center">
              <Icon item={item} />

              <div className={styles.accountFutureSum}>
                <span className={styles.accountSum}>
                  {formattedSum(item.balance)}
                </span>
                <span className={styles.accountCurrency}>
                  {formattedCurrency(item.currency_code)}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </label>
  );
};

export default AccountsSelectItem;
