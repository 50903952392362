import { useContext, useEffect, useState } from "react";
import styles from "./affario-blocks-item.module.css";
import {
  ConfigContext,
  Context,
  RefContext,
} from "../affario-blocks/affario-blocks";

interface IAffarioBlocksItem {
  children: React.ReactNode | React.ReactNode[];
  itemId: number;
}

const AffarioBlocksItem = ({ children, itemId }: IAffarioBlocksItem) => {
  const activeItem = useContext(Context);
  const wrapRef = useContext(RefContext);
  const config = useContext(ConfigContext);

  const [activeCard, setActiveCard] = useState(activeItem);
  const [showCard, setShowCard] = useState(activeItem);
  const [finishCard, setFinishCard] = useState(activeItem);

  useEffect(() => {
    if (wrapRef.current && activeItem === itemId) {
      wrapRef.current.style.height = wrapRef.current.clientHeight + "px";
    }

    setShowCard(activeItem);

    setTimeout(() => {
      setActiveCard(activeItem);
      setTimeout(() => {
        if (wrapRef.current !== null) {
          let activeItem = wrapRef.current.querySelector(".active");
          let contentH = activeItem ? activeItem.clientHeight : 0;
          wrapRef.current.style.height = contentH + "px";
        }
      });
    });

    setTimeout(() => {
      setFinishCard(activeItem);
      setTimeout(() => {
        if (wrapRef.current !== null) {
          wrapRef.current.style.height = "unset";
        }
      });
    }, config.duration);
  }, [activeItem, config.duration, itemId, wrapRef]);

  return (
    <>
      {(finishCard === itemId || showCard === itemId) && (
        <div
          className={`${styles.card} ${
            activeCard === itemId ? styles.active : ""
          }`}
          style={{ transitionDuration: config.duration + "ms" }}
        >
          <div
            className={`${styles.content} ${
              showCard === itemId ? "active" : ""
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default AffarioBlocksItem;
