import Label from "../ui/label";
import InputSum from "../ui/input-sum";
import Button from "../ui/button";
import styles from "./transfer-form.module.css";
import AccountsSelect from "../ui/accounts-select";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../services/store";
import { addTransaction } from "../../services/actions/user";
import { IAccount } from "../../utils/types";
import AcceptWrap from "../accept-wrap/accept-wrap";

interface IExpenseForm {
  useModal?: boolean;
  onSubmit: () => void;
}

const TransferForm = ({ onSubmit, useModal }: IExpenseForm) => {
  const appConfig = useSelector((state) => state.app);
  const accounts = useSelector((state) => state.user.accounts);

  const dispatch = useDispatch();

  const [accountId, setAccountId] = useState("");
  const [transferAccountId, setTransferAccountId] = useState("");
  const [sum, setSum] = useState(0);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (accounts) {
      setAccountId(accounts[0].id);
      setCurrency(accounts[0].currency_code);
      if (accounts[1] !== null) {
        setTransferAccountId(accounts[1].id);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (accounts && accountId) {
      let selectedAccount = accounts.find((item) => item.id == accountId);
      if (selectedAccount) {
        setCurrency(selectedAccount.currency_code);
      }
    }
  }, [accountId, accounts]);

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendForm();
  };

  const sendForm = () => {
    dispatch(
      addTransaction({
        sum: sum,
        account_id: accountId,
        currency_code: currency,
        type: "expense",
      })
    ).then((data) => {
      dispatch(
        addTransaction({
          sum: sum,
          account_id: transferAccountId,
          currency_code: currency,
          type: "upcoming",
        })
      ).then((data) => {
        onSubmit();
      });
    });
  };

  const setAccountIdHandle = (id: IAccount["id"]) => {
    if (id === transferAccountId && accounts) {
      let newTransferId = accounts.find((item) => item.id !== id)?.id;
      if (newTransferId) {
        setTransferAccountId(newTransferId);
      }
    }
    setAccountId(id);
  };

  return (
    <AcceptWrap
      useModal={useModal}
      onSubmit={onSubmit}
      title="Перевод между счетами"
      useAcceptButton={true}
      sendForm={sendForm}
    >
      <div
        className={`${styles.container} ${
          appConfig.darkMode ? styles.darkMode : ""
        }`}
      >
        <form className={styles.form} onSubmit={onSubmitForm}>
          <Label>Откуда:</Label>
          <AccountsSelect
            name={"account"}
            className={"mb-4"}
            selected={accountId}
            onSelect={setAccountIdHandle}
          />
          <Label>Куда:</Label>
          <AccountsSelect
            name={"transferAccount"}
            className={"mb-4"}
            selected={transferAccountId}
            onSelect={setTransferAccountId}
            options={{
              filter: { currency: currency, selectedAccount: accountId },
            }}
          />
          <Label>Введите сумму:</Label>
          <InputSum
            className={"mb-4"}
            currency={currency}
            inFocus={true}
            sumChange={setSum}
          />
        </form>
      </div>
    </AcceptWrap>
  );
};

export default TransferForm;
