import React, { MouseEvent } from "react";
import styles from "./pop-up-overlay.module.css";
import { useSelector } from "../../services/store";

type TPopUpOverlay = {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
};

const PopUpOverlay = ({ children, onClose }: TPopUpOverlay) => {
  const appConfig = useSelector((state) => state.app);

  const overlayRef = React.useRef<HTMLDivElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <div
      ref={overlayRef}
      onClick={handleClick}
      className={`${appConfig.darkMode && styles.darkMode} ${styles.block}`}
    >
      {children}
    </div>
  );
};

export default PopUpOverlay;
