import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "../../services/store";
import { addTObject } from "../../services/actions/user";
import Label from "../ui/label";
import InputText from "../ui/input-text";
import IconControl from "../ui/icon-control";
import styles from "./objects-add-form.module.css";
import Textarea from "../ui/textarea";
import Button from "../ui/button";

interface IObjectsAddForm {
  onSubmit: () => void;
}

const ObjectsAddForm = ({ onSubmit }: IObjectsAddForm) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [activeIconId, setActiveIconId] = useState(0);
  const [notes, setNotes] = useState("");

  const nameChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const notesChangeEvent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      addTObject({
        name: name,
        icon: activeIconId,
        notes: notes,
      })
    ).then((data) => {
      onSubmit();
    });
  };
  return (
    <div className={`${styles.container} ${darkMode ? styles.darkMode : ""}`}>
      <form className={styles.form} onSubmit={onSubmitForm}>
        <Label>Название</Label>
        <InputText value={name} onChange={nameChangeEvent} inFocus={true} />
        <Label>Иконка</Label>
        <IconControl
          activeIconId={activeIconId}
          setActiveIconId={setActiveIconId}
        />
        <Label>Заметки</Label>
        <Textarea value={notes} onChange={notesChangeEvent} />
        <div className={styles.buttonWrap}>
          <Button>Добавить</Button>
        </div>
      </form>
    </div>
  );
};

export default ObjectsAddForm;
