import IconsForm from "../icons-form/icons-form";
import PopUp from "../pop-up/pop-up";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";
import { IIcon } from "../../utils/types";

interface IIconControl {
  activeIconId: number;
  setActiveIconId: React.Dispatch<React.SetStateAction<number>>;
  activeCurrencyCode?: string;
  currentIconId?: number;
}

const IconControl = ({
  activeIconId,
  setActiveIconId,
  activeCurrencyCode,
  currentIconId,
}: IIconControl) => {
  const appConfig = useSelector((state) => state.app);

  const iconsItemRefs = useRef<(HTMLInputElement | null)[]>([]);
  const otherIconRef = useRef<null | HTMLInputElement>(null);

  const [otherIconSelected, setOtherIconSelected] = useState<IIcon | null>(
    null
  );

  const [showIconsPopUp, setShowIconsPopUp] = useState(false);

  const onIconSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveIconId(Number(e.target.value));
  };

  const OnShowIconsPopUp = () => {
    setShowIconsPopUp(true);
  };

  const OnIconsPopUpCloseHandle = () => {
    setShowIconsPopUp(false);
  };

  const SetOtherIconSelectedHandle = (iconItem: IIcon) => {
    if (
      appConfig.icons &&
      appConfig.icons.slice(0, 4).find((item) => item.id === iconItem.id)
    ) {
      setOtherIconSelected(null);
      if (iconsItemRefs.current) {
        let findedItem = iconsItemRefs.current.find(
          (item) => Number(item?.value) === iconItem.id
        );
        if (findedItem) {
          setActiveIconId(Number(findedItem.value));
        }
      }
    } else {
      setOtherIconSelected(iconItem);
      if (otherIconRef.current) {
        setActiveIconId(iconItem.id);
      }
    }
    setShowIconsPopUp(false);
  };

  useEffect(() => {
    if (currentIconId && appConfig.icons) {
      let findC = appConfig.icons.find((item) => item.id === currentIconId);
      if (findC) {
        SetOtherIconSelectedHandle(findC);
      }
    }
  }, [currentIconId]);

  return (
    <>
      <div
        className={`${styles.icons} ${
          appConfig.darkMode ? styles.darkMode : ""
        }`}
      >
        <label key={-1} className={styles.iconsItemWrap}>
          <input
            ref={otherIconRef}
            type="radio"
            name="icon"
            value={0}
            onChange={onIconSelect}
            checked={activeIconId === 0}
          />
          <div className={styles.iconsItem}>
            <div className={styles.iconsCurrency}>
              {appConfig.currencies && activeCurrencyCode
                ? appConfig.currencies.filter(
                    (item) => item.code === activeCurrencyCode
                  )[0].symbol
                : "?"}
            </div>
          </div>
        </label>
        <label
          key={0}
          className={`${styles.iconsItemWrap} ${
            otherIconSelected ? "" : "d-none"
          } `}
        >
          <input
            ref={otherIconRef}
            type="radio"
            name="icon"
            value={otherIconSelected?.id}
            onChange={onIconSelect}
            checked={activeIconId === otherIconSelected?.id}
          />
          <div className={styles.iconsItem}>
            {otherIconSelected?.type === "img" ? (
              <img
                className={styles.iconsItemImage}
                src={otherIconSelected.url}
                alt={otherIconSelected.name}
              />
            ) : (
              <div className={styles.iconsItemEmoji}>
                {otherIconSelected?.symbol}
              </div>
            )}
          </div>
        </label>
        {appConfig.icons &&
          appConfig.icons.slice(0, 4).map((item, index) => (
            <label key={index} className={styles.iconsItemWrap}>
              <input
                ref={(el) => (iconsItemRefs.current[index] = el)}
                type="radio"
                name="icon"
                value={item.id}
                onChange={onIconSelect}
                checked={activeIconId === item.id}
              />
              <div className={styles.iconsItem}>
                {item.type === "img" ? (
                  <img
                    className={styles.iconsItemImage}
                    src={item.url}
                    alt={item.name}
                  />
                ) : (
                  <div className={styles.iconsItemEmoji}>{item.symbol}</div>
                )}
              </div>
            </label>
          ))}
        <div onClick={OnShowIconsPopUp} className={styles.iconsItem}>
          ...
        </div>
      </div>
      {showIconsPopUp && (
        <PopUp onClose={OnIconsPopUpCloseHandle}>
          <IconsForm
            activeItem={activeIconId}
            onSelect={SetOtherIconSelectedHandle}
          />
        </PopUp>
      )}
    </>
  );
};

export default IconControl;
