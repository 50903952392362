import ExpenseObject from "../expense-object/expense-object";
import styles from "./expense-objects.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useSelector } from "../../services/store";

const ExpenseObjects = () => {
  const userData = useSelector((state) => state.user);

  return (
    <div className={styles.items}>
      <Swiper slidesPerView={2} spaceBetween={-20} className={styles.swiper}>
        {userData.objects && userData.objects.map((item) => (
          <SwiperSlide key={item.id}>
            <ExpenseObject item={item} />
          </SwiperSlide>
        ))}
        <SwiperSlide key={0}>
          <ExpenseObject />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ExpenseObjects;
