import styles from "./app-header.module.css";

import noProfilePhoto from "../../images/person.jpg";
import { useSelector } from "../../services/store";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserPhoto from "../ui/user-photo";
import BalanceWidget from "../widgets/balance-widget";
const AppHeader = () => {
  const appConfig = useSelector((state) => state.app);
  const user = useSelector((state) => state.user.user);
  const accounts = useSelector((state) => state.user.accounts);

  const profilePhoto: string = noProfilePhoto;

  const [currentSum, setCurrentSum] = useState(0);

  useEffect(() => {
    setCurrentSum(
      accounts
        ? accounts
            .filter((item) => item.currency_code === "RUB")
            .reduce((sum, account) => sum + account.balance, 0)
        : 0
    );
  }, [accounts]);

  type TCurrency = "RUB" | "USD";

  const currentSumCurrency: TCurrency = "RUB";

  const accountInfo = {};

  return (
    <>
      <header
        className={`${styles.header} ${
          appConfig.darkMode ? styles.darkMode : ""
        } ${appConfig.contentScrolled ? styles.scrolled : ""}`}
      >
        <div>
          <Link
            to="/profile"
            className="d-flex align-items-center text-decoration-none"
          >
            <UserPhoto />
            <span className={styles.profileName}>{user && user.name}</span>
          </Link>
        </div>
        <div className={styles.balanceBlock}>
          {accountInfo ? (
            <BalanceWidget
              sum={currentSum}
              currency={currentSumCurrency}
              title={"Всего на счетах ₽"}
            />
          ) : (
            <div className={styles.noAccount}>Добавьте счет</div>
          )}
        </div>
      </header>
    </>
  );
};

export default AppHeader;
