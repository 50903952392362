import React from "react";
import styles from "./pop-up.module.css";
import PopUpOverlay from "../pop-up-overlay/pop-up-overlay";
import { useSelector } from "../../services/store";

type TPopUp = {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
};

const PopUp = ({ children, onClose }: TPopUp) => {
  const appConfig = useSelector((state) => state.app);

  const closeByKeyEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", closeByKeyEsc, false);

    return () => {
      document.removeEventListener("keydown", closeByKeyEsc, false);
    };
  });

  return (
    <PopUpOverlay onClose={onClose}>
      <div
        className={`${appConfig.darkMode && styles.darkMode} ${styles.block}`}
      >
        {children}
      </div>
    </PopUpOverlay>
  );
};

export default PopUp;
