import UpcomingExpense from "../upcoming-expense/upcoming-expense";
import styles from "./upcoming-expenses.module.css";
import noCarPhoto from "../../images/car.jpg";
import noHousePhoto from "../../images/house.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "../../services/store";

const UpcomingExpenses = () => {
  const appConfig = useSelector((state) => state.app);

  type TUpcomingExpense = {
    id: number;
    icon: string;
    name: string;
    sum: number;
    date: string;
    category: string;
  };
  const expenseObjects: TUpcomingExpense[] = [
    {
      id: 3,
      icon: noHousePhoto,
      name: "Квартплата",
      sum: 24000,
      date: "Сегодня",
      category: "Квартира",
    },
    {
      id: 1,
      icon: noCarPhoto,
      name: "Продление страховки",
      sum: 12000,
      date: "Завтра",
      category: "Автомобиль",
    },
  ];
  return (
    <>
      {expenseObjects.length > 0 && (
        <>
          <div className="d-flex align-items-center justify-content-between block-title">
            <h5>Ближайшие расходы</h5>
            {expenseObjects.length > 1 && <Link to="/">Все операции</Link>}
          </div>
          <div
            className={`${styles.itemsWrap} ${
              appConfig.darkMode && styles.darkMode
            }`}
          >
            {expenseObjects.length > 0 ? (
              <div className={styles.items}>
                {expenseObjects.map((item) => (
                  <UpcomingExpense key={item.id} item={item} />
                ))}
              </div>
            ) : (
              <div className="no-transactions">Нет операции</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UpcomingExpenses;
