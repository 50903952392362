import { useSelector } from "../../services/store";
import styles from "./upcoming-deposit.module.css";

type TUpcomingDeposit = {
  id: number;
  icon: string;
  name: string;
  sum: number;
  date: string;
  category: string;
};
type FormatDateProps = {
  item: TUpcomingDeposit;
};

const UpcomingDeposit = ({ item }: FormatDateProps) => {
  const appConfig = useSelector((state) => state.app);

  type TCurrency = "RUB" | "USD";

  const futureSumCurrency: TCurrency = "RUB";

  const formattedSum = (sum: number): string => {
    return new Intl.NumberFormat("ru-RU").format(sum);
  };

  const formattedCurrency = (currency: TCurrency): string => {
    switch (currency) {
      case "RUB":
        return "₽";
      case "USD":
        return "$";
    }
  };

  return (
    <div
      className={`${styles.itemWrap} ${appConfig.darkMode && styles.darkMode}`}
    >
      <div className={styles.item}>
        <img className={styles.icon} src={item.icon} alt={item.name} />
        <div className={styles.info}>
          <div className={styles.name}>
            {item.name}
            <div className={styles.category}>{item.category}</div>
          </div>
          <div>
            <div className={styles.futureSum}>
              <span className={styles.sum}>+{formattedSum(item.sum)}</span>
              <span className={styles.currency}>
                {formattedCurrency(futureSumCurrency)}
              </span>
            </div>
            <div className={styles.date}>{item.date}</div>
          </div>
          <div className={styles.controls}>
            <div className={styles.control}>Ok</div>
            <div className={styles.control}>?</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingDeposit;
