import { createSlice } from "@reduxjs/toolkit";
import {
  ICurrency,
  IIcon,
  IIconsCategories,
  ICategoriesSet,
} from "../utils/types";

const userSlice = createSlice({
  name: "app",
  initialState: {
    darkMode: false,
    contentScrolled: false,
    currencies: null as ICurrency[] | null,
    icons: null as IIcon[] | null,
    iconsCategories: null as IIconsCategories[] | null,
    categoriesSets: null as ICategoriesSet[] | null,
  },
  reducers: {
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setScrollTop: (state, action) => {
      state.contentScrolled = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload ?? null;
    },
    setIcons: (state, action) => {
      state.icons = action.payload ?? null;
    },
    setIconsCategories: (state, action) => {
      state.iconsCategories = action.payload ?? null;
    },
    setCategoriesSets: (state, action) => {
      state.categoriesSets = action.payload ?? null;
    },
  },
});

export const {
  setDarkMode,
  setScrollTop,
  setCurrencies,
  setIcons,
  setIconsCategories,
  setCategoriesSets,
} = userSlice.actions;

export default userSlice.reducer;
