import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../services/store";
import { addAccount, addTObject, deleteAccount, deleteTObject } from "../../services/actions/user";
import Label from "../ui/label";
import InputText from "../ui/input-text";
import InputSum from "../ui/input-sum";
import CurrencyControl from "../ui/currency-control";
import IconControl from "../ui/icon-control";
import styles from "./objects-edit-form.module.css";
import Textarea from "../ui/textarea";
import Button from "../ui/button";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "../pop-up/pop-up";

interface IAccountEditForm {
  onSubmit: () => void;
}

const ObjectsEditForm = ({ onSubmit }: IAccountEditForm) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const objects = useSelector((state) => state.user.objects);

  const { id } = useParams();
  const navigate = useNavigate();

  const object = objects?.find((item) => item.id === id);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [currentIconId, setCurrentIconId] = useState(0);
  const [activeIconId, setActiveIconId] = useState(0);
  const [notes, setNotes] = useState("");
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  useEffect(() => {
    if (object) {
      setName(object.name);
      setCurrentIconId(Number(object.icon));
      setActiveIconId(Number(object.icon));
      if (object.notes) {
        setNotes(object.notes);
      }
    }
  }, [object]);

  const nameChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const notesChangeEvent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (object) {
      dispatch(
        addTObject({
          id: object.id,
          name: name,
          icon: activeIconId,
          notes: notes,
        })
      ).then((data) => {
        onSubmit();
      });
    }
  };

  const deleteAcc = () => {
    if (object) {
      dispatch(
        deleteTObject({
          id: object.id,
        })
      ).then((data) => {
        navigate("/");
      });
    }
  };

  const OnDeletePopUpCloseHandle = () => {
    setShowDeletePopUp(false);
  };
  return (
    <div className={`${styles.container} ${darkMode ? styles.darkMode : ""}`}>
      <form className={styles.form} onSubmit={onSubmitForm}>
        <Label>Название</Label>
        <InputText value={name} onChange={nameChangeEvent} />
        <Label>Иконка</Label>
        <IconControl
          currentIconId={currentIconId}
          activeIconId={activeIconId}
          setActiveIconId={setActiveIconId}
        />
        <Label>Заметки</Label>
        <Textarea value={notes} onChange={notesChangeEvent} />
        <div className={styles.buttonWrap}>
          <Button>Сохранить</Button>
          <Button
            onClick={() => setShowDeletePopUp(true)}
            type="button"
            buttonStyle="danger"
          >
            Удалить объект
          </Button>
          {showDeletePopUp && (
            <PopUp onClose={OnDeletePopUpCloseHandle}>
              <p>
                Удалить объект {object?.name}?
              </p>
              <div className={styles.buttonWrap}>
                <Button onClick={deleteAcc} type="button" buttonStyle="danger">
                  Да
                </Button>
                <Button
                  onClick={() => setShowDeletePopUp(false)}
                  type="button"
                  buttonStyle="light"
                >
                  Отменить
                </Button>
              </div>
            </PopUp>
          )}
        </div>
      </form>
    </div>
  );
};

export default ObjectsEditForm;
