import { ChangeEvent, useEffect, useRef, useState } from "react";
import CurrenciesForm from "../currencies-form/currencies-form";
import PopUp from "../pop-up/pop-up";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";
import { ICurrency } from "../../utils/types";

interface ICurrencyControl {
  activeCurrencyCode: string;
  setActiveCurrencyCode: React.Dispatch<React.SetStateAction<string>>;
  currentCurrencyCode?: string;
}

const CurrencyControl = ({
  activeCurrencyCode,
  setActiveCurrencyCode,
  currentCurrencyCode,
}: ICurrencyControl) => {
  const appConfig = useSelector((state) => state.app);

  const otherCurrencyRef = useRef<null | HTMLInputElement>(null);
  const currencyItemRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [otherCurrencySelected, setOtherCurrencySelected] =
    useState<ICurrency | null>(null);
  const [showCurrenciesPopUp, setShowCurrenciesPopUp] = useState(false);

  const onCurrencySelect = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveCurrencyCode(e.target.value);
  };

  const OnShowCurrenciesPopUp = () => {
    setShowCurrenciesPopUp(true);
  };

  const OnCloseHandle = () => {
    setShowCurrenciesPopUp(false);
  };

  const SetOtherCurrencySelectedHandle = (currencyItem: ICurrency) => {
    if (
      appConfig.currencies &&
      appConfig.currencies
        .slice(0, 4)
        .find((item) => item.code === currencyItem.code)
    ) {
      setOtherCurrencySelected(null);
      if (currencyItemRefs.current) {
        let findedItem = currencyItemRefs.current.find(
          (item) => item?.value === currencyItem.code
        );
        if (findedItem) {
          setActiveCurrencyCode(findedItem.value);
        }
      }
    } else {
      setOtherCurrencySelected(currencyItem);
      if (otherCurrencyRef.current) {
        setActiveCurrencyCode(currencyItem.code);
      }
    }
    setShowCurrenciesPopUp(false);
  };

  useEffect(() => {
    if (currentCurrencyCode && appConfig.currencies) {
      let findC = appConfig.currencies.find(
        (item) => item.code === currentCurrencyCode
      );
      if (findC) {
        SetOtherCurrencySelectedHandle(findC);
      }
    }
  }, [currentCurrencyCode]);

  return (
    <>
      <div
        className={`${styles.currencies} ${
          appConfig.darkMode ? styles.darkMode : ""
        }`}
      >
        <label
          key={0}
          className={`${styles.currenciesItemWrap} ${
            otherCurrencySelected ? "" : "d-none"
          } `}
        >
          <input
            ref={otherCurrencyRef}
            type="radio"
            name="currency"
            value={otherCurrencySelected?.code}
            onChange={onCurrencySelect}
            checked={activeCurrencyCode === otherCurrencySelected?.code}
          />
          <div className={styles.currenciesItem}>
            {otherCurrencySelected?.symbol}
          </div>
        </label>
        {appConfig.currencies &&
          appConfig.currencies.slice(0, 4).map((item, index) => (
            <label key={index} className={styles.currenciesItemWrap}>
              <input
                ref={(el) => (currencyItemRefs.current[index] = el)}
                type="radio"
                name="currency"
                value={item.code}
                onChange={onCurrencySelect}
                checked={activeCurrencyCode === item.code}
              />
              <div className={styles.currenciesItem}>{item.symbol}</div>
            </label>
          ))}
        <div onClick={OnShowCurrenciesPopUp} className={styles.currenciesItem}>
          ...
        </div>
      </div>
      {showCurrenciesPopUp && (
        <PopUp onClose={OnCloseHandle}>
          <CurrenciesForm
            activeItem={activeCurrencyCode}
            onSelect={SetOtherCurrencySelectedHandle}
          />
        </PopUp>
      )}
    </>
  );
};
export default CurrencyControl;
