import Label from "../ui/label";
import InputSum from "../ui/input-sum";
import Button from "../ui/button";
import styles from "./upcoming-form.module.css";
import AccountsSelect from "../ui/accounts-select";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../services/store";
import { addTransaction } from "../../services/actions/user";
import Modal from "../modal/modal";
import Page from "../page/page";
import AcceptWrap from "../accept-wrap/accept-wrap";

interface IExpenseForm {
  useModal?: boolean;
  onSubmit: () => void;
}

const UpcomingForm = ({ onSubmit, useModal }: IExpenseForm) => {
  const appConfig = useSelector((state) => state.app);
  const accounts = useSelector((state) => state.user.accounts);

  const dispatch = useDispatch();

  const [accountId, setAccountId] = useState("");
  const [sum, setSum] = useState(0);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (accounts) {
      setAccountId(accounts[0].id);
      setCurrency(accounts[0].currency_code);
    }
  }, [accounts]);

  useEffect(() => {
    if (accounts && accountId) {
      let selectedAccount = accounts.find((item) => item.id == accountId);
      if (selectedAccount) {
        setCurrency(selectedAccount.currency_code);
      }
    }
  }, [accountId, accounts]);

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendForm();
  };

  const sendForm = () => {
    dispatch(
      addTransaction({
        sum: sum,
        account_id: accountId,
        currency_code: currency,
        type: "upcoming",
      })
    ).then((data) => {
      onSubmit();
    });
  };

  return (
    <AcceptWrap
      useModal={useModal}
      onSubmit={onSubmit}
      title="Фиксация дохода"
      useAcceptButton={true}
      sendForm={sendForm}
    >
      <div
        className={`${styles.container} ${
          appConfig.darkMode ? styles.darkMode : ""
        }`}
      >
        <form className={styles.form} onSubmit={onSubmitForm}>
          <Label>Введите сумму:</Label>
          <InputSum
            className={"mb-4"}
            currency={currency}
            inFocus={true}
            sumChange={setSum}
          />
          <Label>Счёт пополнения:</Label>
          <AccountsSelect
            className={"mb-4"}
            selected={accountId}
            onSelect={setAccountId}
          />
        </form>
      </div>
    </AcceptWrap>
  );
};

export default UpcomingForm;
