import { useEffect, useRef, useState } from "react";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";

interface IInputSum {
  inFocus?: boolean;
  sumChange?: React.Dispatch<React.SetStateAction<number>>;
  value?: number;
  className?: string;
  currency?: string;
}
const InputSum = ({
  inFocus,
  sumChange,
  value,
  className,
  currency,
}: IInputSum) => {
  const darkMode = useSelector((state) => state.app.darkMode);
  const currencies = useSelector((state) => state.app.currencies);

  const [currencySymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    if (currency && currencies) {
      let currencyItem = currencies.find((item) => item.code === currency);
      if (currencyItem) {
        setCurrencySymbol(currencyItem.symbol);
      }
    }
  }, [currency, currencies]);

  useEffect(() => {
    if (inFocus) {
      onInputFocus();
    }
  }, [inFocus]);

  const sumRef = useRef<null | HTMLInputElement>(null);
  const currentSumRef = useRef<null | HTMLDivElement>(null);

  let prevSelectionPos = 0;

  useEffect(() => {
    if (sumRef.current) {
      if (value) {
        sumRef.current.value = String(value);
        onInput();
      } else {
        sumRef.current.value = "0";
      }

      if (currentSumRef.current) {
        currentSumRef.current.innerHTML = sumRef.current.value;
        sumRef.current.style.width =
          currentSumRef.current.offsetWidth + 5 + 15 + "px";
      }
    }
  }, [value]);

  document.onselectionchange = () => {
    if (sumRef.current) {
      let selectionStart = sumRef.current.selectionStart
        ? sumRef.current.selectionStart
        : 0;

      if (selectionStart < prevSelectionPos) {
        if (
          (sumRef.current.value.charAt(selectionStart - 1).match(/\s/g) || [])
            .length
        ) {
          sumRef.current.setSelectionRange(
            selectionStart - 1,
            selectionStart - 1
          );
        }
      } else {
        if (
          (sumRef.current.value.charAt(selectionStart - 1).match(/\s/g) || [])
            .length
        ) {
          sumRef.current.setSelectionRange(
            selectionStart + 1,
            selectionStart + 1
          );
        }
      }
      prevSelectionPos = selectionStart;
    }
  };

  function InvalidMsg() {
    if (sumRef.current) {
      if (
        sumRef.current.value.length === 0 ||
        parseInt(sumRef.current.value.replace(/ /g, "")) === 0
      ) {
        sumRef.current.setCustomValidity("Введите сумму");
      } else {
        sumRef.current.setCustomValidity("");
      }
    }
    return true;
  }

  const stringLength = 18; // Длинна строки без учета пробелов
  const maxLength =
    stringLength % 3 === 0
      ? stringLength + (Math.floor(stringLength / 3) - 1)
      : stringLength % 3 !== 0
      ? stringLength + Math.floor(stringLength / 3)
      : undefined;

  const sumFloatlen = 2;

  const onInput = () => {
    if (sumRef.current) {
      let selectionStart = sumRef.current.selectionStart
        ? sumRef.current.selectionStart
        : 0;
      let selectionStartSpaces = (
        sumRef.current.value
          .replace(new RegExp("^.{0," + selectionStart + "}", "g"), "$&")
          .match(/\s/g) || []
      ).length;

      InvalidMsg();

      if (
        parseFloat(sumRef.current.value.replace(/ /g, "")) > 100000000000000
      ) {
        sumRef.current.value = "100 000 000 000 000";
      }

      let inputValue = sumRef.current.value;

      inputValue = inputValue
        .replace(/\./g, ",")
        .replace(/[^\d,]/g, "")
        .replace(/^0+(\d+)/, "$1")
        .replace(/^,+($|\d+)/, "0,$1")
        .replace(/^,(,+)/, ",$'");

      let x = inputValue.match(
        new RegExp("(\\d|\\s)+(\\,\\d{0," + sumFloatlen + "})", "g")
      );
      if (x && x.length > 0) {
        inputValue = x[0];
      }

      if (inputValue.indexOf(",") !== -1) {
        sumRef.current.value = inputValue.replace(
          /(\d)(?=(\d{3})+(,))/g,
          "$& "
        );
      } else {
        sumRef.current.value = inputValue.replace(
          /(\d)(?=(\d{3})+($))/g,
          "$& "
        );
      }

      let selectionFinalSpaces = (
        sumRef.current.value
          .replace(new RegExp("^.{0," + selectionStart + "}", "g"), "$&")
          .match(/\s/g) || []
      ).length;

      let newSelectionPos = selectionStart;
      if (selectionStartSpaces > selectionFinalSpaces) {
        newSelectionPos -= 1;
      } else if (selectionStartSpaces < selectionFinalSpaces) {
        newSelectionPos += 1;
      }

      sumRef.current.setSelectionRange(newSelectionPos, newSelectionPos);

      if (currentSumRef.current) {
        currentSumRef.current.innerHTML = sumRef.current.value;
        sumRef.current.style.width =
          currentSumRef.current.offsetWidth + 5 + 15 + "px";
      }

      if (sumChange) {
        sumChange(
          Number(sumRef.current.value.replace(/ /gi, "").replace(",", "."))
        );
      }
    }
  };

  const onInputFocus = () => {
    if (sumRef.current) {
      // sumRef.current.setSelectionRange(sumRef.current.value.length, sumRef.current.value.length);
      sumRef.current.focus();
    }
  };
  const onInputSelectionRange = () => {
    if (sumRef.current) {
      sumRef.current.setSelectionRange(
        sumRef.current.value.length,
        sumRef.current.value.length
      );
    }
  };

  return (
    <>
      <div ref={currentSumRef} className={styles.inputMaskWrap}>
        0
      </div>
      <div
        className={`${styles.inputWrap} ${darkMode ? styles.darkMode : ""} ${
          className ? className : ""
        }`}
        onClick={onInputFocus}
      >
        <input
          type="text"
          className={styles.inputWithoutCurrency}
          maxLength={maxLength}
          onInvalid={(e) => InvalidMsg}
          onInput={onInput}
          ref={sumRef}
          inputMode="decimal"
        />
        {currency && currencySymbol && (
          <span onClick={onInputSelectionRange}>{currencySymbol}</span>
        )}
      </div>
    </>
  );
};

export default InputSum;
