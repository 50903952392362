import { Ref, RefObject, createContext, useRef } from "react";
import styles from "./affario-blocks.module.css";

export const ConfigContext: React.Context<{ duration: number }> = createContext(
  { duration: 300 }
);
export const Context: React.Context<number> = createContext(1);
export const RefContext = createContext(
  null as unknown as RefObject<HTMLDivElement>
);

interface IAffarioBlocks {
  children: React.ReactNode | React.ReactNode[];
  currentId: number;
  duration?: number;
}

const AffarioBlocks = ({ children, currentId, duration }: IAffarioBlocks) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const config = {
    duration: duration ? duration : 300,
  };
  return (
    <ConfigContext.Provider value={config}>
      <RefContext.Provider value={wrapRef}>
        <Context.Provider value={currentId}>
          <div
            ref={wrapRef}
            style={{ transitionDuration: config.duration + "ms" }}
            className={`${styles.wrap}`}
          >
            {children}
          </div>
        </Context.Provider>
      </RefContext.Provider>
    </ConfigContext.Provider>
  );
};

export default AffarioBlocks;
