import React, { useEffect, useRef, useState } from "react";
import styles from "./icons-form.module.css";
import { useSelector } from "../../services/store";
import { IIcon } from "../../utils/types";

interface IIconsForm {
  onSelect: (iconItem: IIcon) => void;
  activeItem: number;
}

const IconsForm = ({ onSelect, activeItem }: IIconsForm) => {
  const appConfig = useSelector((state) => state.app);

  const searchRef = useRef<HTMLInputElement | null>(null);

  const [searchValue, setSearchValue] = useState("");
  const [searchedItems, setSearchedItems] = useState(appConfig.icons);

  useEffect(() => {
    if (appConfig.icons) {
      if (searchValue.length > 0) {
        setSearchedItems(
          appConfig.icons.filter((item) =>
            (
              item.name.toLowerCase() +
              " " +
              item.keywords.toLowerCase() +
              " " +
              item.symbol.toLowerCase()
            ).includes(searchValue.toLowerCase())
          )
        );
      } else {
        setSearchedItems(appConfig.icons);
      }
    }
  }, [searchValue, appConfig.icons]);

  const onSearchInput = () => {
    if (searchRef.current) {
      setSearchValue(searchRef.current.value);
    }
  };

  return (
    <div className={`${appConfig.darkMode && styles.darkMode} ${styles.block}`}>
      <div className={styles.searchBlock}>
        <input
          ref={searchRef}
          onInput={onSearchInput}
          type="text"
          className={styles.searchInput}
          placeholder="Поиск иконок"
          value={searchValue}
        />
      </div>
      <div className={styles.iconsWrap}>
        {searchedItems && searchedItems.length > 0 ? (
          <>
            {appConfig.iconsCategories &&
              appConfig.iconsCategories
                .filter((catItem) =>
                  searchedItems
                    .map((item) => item.category)
                    .reduce(
                      (acc: number[], it: number) =>
                        acc.includes(it) ? acc : [...acc, it],
                      []
                    )
                    .includes(catItem.id)
                )
                .map((catItem, index) => (
                  <div key={index}>
                    <h5>{catItem.name}</h5>
                    <div className={styles.icons}>
                      {searchedItems && searchedItems.length > 0 ? (
                        searchedItems
                          .filter((item) => item.category === catItem.id)
                          .map((item, index) => (
                            <div
                              key={index}
                              className={`${styles.iconsItem} ${
                                activeItem === item.id ? styles.active : ""
                              }`}
                              onClick={() => onSelect(item)}
                            >
                              {item.type === "img" ? (
                                <img
                                  className={styles.iconsItemImage}
                                  src={item.url}
                                  alt={item.name}
                                />
                              ) : (
                                <div className={styles.iconsItemEmoji}>
                                  {item.symbol}
                                </div>
                              )}
                            </div>
                          ))
                      ) : (
                        <>Ничего не найдено</>
                      )}
                    </div>
                  </div>
                ))}
          </>
        ) : (
          <>Ничего не найдено</>
        )}
      </div>
    </div>
  );
};

export default IconsForm;
