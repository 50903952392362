import { createSlice } from "@reduxjs/toolkit";
import {
  addAccount,
  addTObject,
  addTransaction,
  closeUserSession,
  deleteAccount,
  deleteTObject,
  login,
  logout,
  register,
  setTheme,
} from "./actions/user";
import {
  IAccount,
  IObjects,
  ITransactionHistory,
  IUser,
  IUserSessions,
  IUserSettings,
} from "../utils/types";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null as IUser | null,
    loaded: false,
    isAuthChecked: false,
    isForgotSend: false,
    preloginUser: null,
    accounts: null as IAccount[] | null,
    transactions: null as ITransactionHistory[] | null,
    objects: null as IObjects[] | null,
    settings: { loaded: false, theme: 0 } as IUserSettings,
    sessions: null as IUserSessions[] | null,
  },
  reducers: {
    setAuthChecked: (state, action) => {
      state.isAuthChecked = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.loaded = action.payload.loaded;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setTObjects: (state, action) => {
      state.objects = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload.settings;
      state.sessions = action.payload.sessions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loaded = action.payload.loaded;
        state.isAuthChecked = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loaded = action.payload.loaded;
        state.isAuthChecked = true;
      })
      .addCase(addAccount.fulfilled, (state, action) => {
        state.accounts = action.payload;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.accounts = action.payload.accounts;
        state.transactions = action.payload.transactions;
      })
      .addCase(addTObject.fulfilled, (state, action) => {
        state.objects = action.payload;
      })
      .addCase(deleteTObject.fulfilled, (state, action) => {
        state.objects = action.payload.objects;
      })
      .addCase(closeUserSession.fulfilled, (state, action) => {
        state.sessions = action.payload.sessions;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.accounts = action.payload.accounts;
        state.transactions = action.payload.transactions;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.loaded = false;
      })
      .addCase(setTheme.fulfilled, (state, action) => {
        state.settings = {
          ...state.settings,
          loaded: true,
        };
        state.sessions = action.payload.sessions;
      });
  },
});

export const {
  setAuthChecked,
  setUser,
  setAccounts,
  setTObjects,
  setTransactions,
  setSettings,
} = userSlice.actions;

export default userSlice.reducer;
