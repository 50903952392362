import { ChangeEvent, useEffect, useRef } from "react";
import styles from "./ui.module.css";
import { useSelector } from "../../services/store";

interface IInputRadio {
  name: string;
  label?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const InputRadio = ({
  name,
  label,
  value,
  onChange,
  checked = false,
}: IInputRadio) => {
  const darkMode = useSelector((state) => state.app.darkMode);

  const inputRef = useRef<null | HTMLInputElement>(null);

  return (
    <label
      className={`${styles.inputRadio} ${darkMode ? styles.darkMode : ""}`}
    >
      <input
        ref={inputRef}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};

export default InputRadio;
